import { useRecoilState } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
export const useMoveToNextQuestion = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);

  const moveToNextQuestion = () => {
    setTimeout(() => {
      setSurvey((prevSurvey) => ({
        ...prevSurvey,
        r_idx: prevSurvey.r_idx + 1
      }));
    }, config.MESSAGE_BUBBLE_DELAY);
  };

  return moveToNextQuestion;
};
