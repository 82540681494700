import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import AlertModal from '../component/AlertModal';
import { ApartmentList } from '../component/ApartmentList';
import { CarList } from '../component/CarList';
import { Footer } from '../component/Footer';
import { Header } from '../component/header/Header';
import { MessageWindow } from '../component/MessageWindow';
import { config } from '../config';
import { useAddLastMessageOption } from '../hook/useAddLastMessageOption';
import { useAddMessage } from '../hook/useAddMessage';
import { useFetchAllQuestions } from '../hook/useFetchAllQuestions';
import { useFetchOriginChartData } from '../hook/useFetchOriginChartData';
import { useFetchReportData } from '../hook/useFetchReportData';
import { useMoveToNextQuestion } from '../hook/useMoveToNextQuestion';
import { useMoveToTheQuestion } from '../hook/useMoveToTheQuestion';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import { surveyState } from '../recoil/atoms';
import '../style/ChatbotPage.css';
// import { saveAnswer } from '../util/saveAnswer';
import { useSaveAnswer } from '../hook/useSaveAnswer';
const SPOUSE_IMG_URL = '/picture/icons/spouse-male.png';

const ChatbotPage = () => {
  const { topic } = useParams();
  const [survey, setSurvey] = useRecoilState(surveyState);
  const {
    r_sessionId,
    r_userId,
    r_surveyId,
    r_allQuestions,
    r_idx,
    r_messages
  } = survey;
  const saveAnswer = useSaveAnswer();
  const addMessage = useAddMessage();
  const moveToNextQuestion = useMoveToNextQuestion();
  const moveToTheQuestion = useMoveToTheQuestion();
  const setSurveyValue = useSetSurveyValue();
  const addLastMessageOption = useAddLastMessageOption();
  const fetchAllQuestions = useFetchAllQuestions();
  const fetchOriginChartData = useFetchOriginChartData();
  const fetchReportData = useFetchReportData();
  const overlayRef = useRef(null);
  const [isRecommendationPopupOpen, setRecommendationPopupOpen] =
    useState(false);
  const [isServiceUnavailablePopupOpen, setServiceUnavailablePopupOpen] =
    useState(false);

  const toggleRecommendationPopup = () => {
    setRecommendationPopupOpen((prevState) => !prevState);
  };

  const toggleServiceUnavailablePopup = () => {
    setServiceUnavailablePopupOpen((prevState) => !prevState);
  };

  const handleRecommendation = () => {
    toggleServiceUnavailablePopup();
    toggleRecommendationPopup();
    saveAnswer(
      r_sessionId,
      r_userId,
      r_surveyId,
      config.SPOUSE_RECOMMENDATION_QUESTION_ID,
      1,
      null
    );
  };

  const handleNoRecommendation = () => {
    toggleRecommendationPopup();
    saveAnswer(config.SPOUSE_RECOMMENDATION_QUESTION_ID, 0, null);
  };

  // useEffect(() => {
  //   const chatTopic = topic || 'General';
  //   const newSurveyId = ['General', 'Finance'].includes(chatTopic) ? 1 : 1;
  //   setSurveyValue('r_surveyId', newSurveyId);
  // }, [topic, setSurvey]);
  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   const idx = params.get('idx');
  //   console.log('idx', idx);
  //   if (idx) {
  //     setSurveyValue('r_idx', parseInt(idx));
  //   }
  // }, []);

  useEffect(() => {
    if (!survey.r_sessionId || survey.r_sessionId === '0') {
      const uuid = uuidv4();
      setSurveyValue('r_sessionId', uuid);
    }
  }, []);
  useEffect(() => {
    console.log('r_userId', survey.r_userId);
  }, [survey.r_userId]);

  useEffect(() => {
    console.log('useEffect1', r_allQuestions.length);
    if (r_messages.length === 0 && r_allQuestions.length === 0) {
      console.log('fetching questions');
      fetchAllQuestions(r_surveyId);
    }
  }, [r_surveyId]);

  useEffect(() => {
    console.log('useEffect2');
    if (r_allQuestions?.length > 0) {
      const params = new URLSearchParams(window.location.search);
      const idx = params.get('idx');
      if (idx) {
        setSurveyValue('r_idx', parseInt(idx));
        return;
      }

      let tempIdx = r_idx;
      addMessage(r_allQuestions[tempIdx]);
      while (r_allQuestions[tempIdx]?.questionType === 'COMMENT') {
        tempIdx++;
      }

      setSurveyValue('r_idx', tempIdx);
    }
  }, [r_allQuestions]);

  useEffect(() => {
    console.log('useEffect3');
    let tempIdx = r_idx;
    addMessage(r_allQuestions[tempIdx]);
    while (r_allQuestions[tempIdx]?.questionType === 'COMMENT') {
      tempIdx++;
      addMessage(r_allQuestions[tempIdx]);
    }
    setSurveyValue('r_idx', tempIdx);
  }, []);

  useEffect(() => {
    console.log('useEffect4: idx', r_idx, survey.r_selectedPurchase);
    if (r_allQuestions.length > 0 && r_allQuestions[r_idx]) {
      addMessage(r_allQuestions[r_idx]);

      if (r_allQuestions[r_idx].questionType === 'SPOUSE_NAMECARD') {
        setTimeout(() => {
          toggleRecommendationPopup();
        }, 750);
      }
      if (
        r_allQuestions[r_idx].questionType === 'COMMENT' ||
        r_allQuestions[r_idx].questionType === 'CAR_NAMECARD' ||
        r_allQuestions[r_idx].questionType === 'APARTMENT_NAMECARD' ||
        r_allQuestions[r_idx].questionType === 'SELF_NAMECARD'
      ) {
        moveToNextQuestion();
      }
      if (
        r_allQuestions[r_idx].questionType === 'END_PROFILE' ||
        r_allQuestions[r_idx].questionType === 'SIMULATION_START'
      ) {
        console.log(
          r_allQuestions[r_idx].questionType,
          survey.r_selectedPurchase[0]?.optionText,
          survey.r_selectedPurchase[0]?.orderText === '차량 구매하기'
        );
        if (survey.r_selectedPurchase[0]?.optionText === '차량 구매하기') {
          moveToTheQuestion(config.CAR_PRICE_IDX);
        } else if (
          survey.r_selectedPurchase[0]?.optionText === '아파트 구하기'
        ) {
          moveToTheQuestion(config.APARTMENT_JEONSE_SALE_IDX);
        } else if (
          survey.r_selectedPurchase[0]?.optionText === '자녀 양육하기'
        ) {
          moveToTheQuestion(config.CHILDREN_SURVEY_IDX);
        } else if (
          survey.r_selectedPurchase[0]?.optionText === '기타 목표 달성하기'
        ) {
          moveToTheQuestion(config.EXTRA_GOAL_SURVEY_IDX);
        } else {
          console.log('moveToNextQuestion', r_idx);
          moveToNextQuestion();
        }
      }
      // 아파트 상세주소 선택 추가
      if (r_allQuestions[r_idx].questionId === config.ADDRESS2_IDX) {
        switch (survey.r_apartmentAddress_1) {
          case '수도권':
            addLastMessageOption(config.CAPITAL_AREA);
            break;
          case '경상권':
            addLastMessageOption(config.KYUNGSANG_AREA);
            break;
          case '충청권':
            addLastMessageOption(config.CHOONGCHUNG_AREA);
            break;
          case '전라권':
            addLastMessageOption(config.JEONRA_AREA);
            break;
          case '강원권':
            addLastMessageOption(config.KWANGWON_AREA);
            break;
          case '제주권':
            addLastMessageOption(config.JEJU_AREA);
            break;
        }
      }
      if (r_allQuestions[r_idx].questionId === config.END_IDX) {
        console.log('end');

        const noSpendBody = {
          user: {
            sex: survey.r_gender,
            age: Number(survey.r_age),
            salary: Number(survey.r_selfIncome),
            saving: Number(survey.r_selfSaving),
            retire_age: Number(survey.r_selfRetirementAge),
            is_married: false,
            has_children: false,
            is_single_income: true,
            saving_mode: 'together',
            number_of_children: 0,
            cash:
              survey.r_selfCashAsset === '--'
                ? 0
                : Number(survey.r_selfCashAsset),
            stock:
              survey.r_selfInvestmentAsset === '--'
                ? 0
                : Number(survey.r_selfInvestmentAsset),
            home_loan:
              survey.r_selfMortgage === '--'
                ? 0
                : Number(survey.r_selfMortgage),
            debt:
              survey.r_selfEtcDebt === '--' ? 0 : Number(survey.r_selfEtcDebt)
          },
          spouse: {
            sex: survey.r_gender === 'male' ? 'female' : 'male',
            age: Number(survey.r_age),
            salary: Number(survey.r_spouseIncome),
            saving: Number(survey.r_spouseSaving),
            retire_age: Number(survey.r_spouseRetirementAge),
            is_married: false,
            has_children: false,
            is_single_income: true,
            saving_mode: 'together',
            number_of_children: 0,
            cash:
              survey.r_spouseCashAsset === '--'
                ? 0
                : Number(survey.r_spouseCashAsset),
            stock:
              survey.r_spouseInvestmentAsset === '--'
                ? 0
                : Number(survey.r_spouseInvestmentAsset),
            home_loan:
              survey.r_spouseMortgage === '--'
                ? 0
                : Number(survey.r_spouseMortgage),
            debt:
              survey.r_spouseEtcDebt === '--'
                ? 0
                : Number(survey.r_spouseEtcDebt)
          },
          marriage_year: Number(survey.r_marriageYear),
          have_children: Number(survey.r_children_age) > 0
        };

        const spendBody = {
          ...noSpendBody,
          marriage_year: Number(survey.r_marriageYear),
          have_children: Number(survey.r_children_age) > 0
        };
        if (
          survey.r_selectedPurchase.some(
            (item) => item.optionText === '자동차 구입하기'
          )
        ) {
          spendBody.car_purchase = {
            car_price: Math.floor(Number(survey.r_carPrice) / 1000),
            car_purchase_age:
              Number(survey.r_age) + Number(survey.r_marriageYear) + 1
          };
        }
        if (
          survey.r_selectedPurchase.some(
            (item) => item.optionText === '아파트 구입하기'
          )
        ) {
          spendBody.house_purchase = {
            house_price: Math.floor(Number(survey.r_apartmentPrice) / 1000),
            house_purchase_age:
              Number(survey.r_age) + Number(survey.r_marriageYear) + 8,
            house_type: survey.r_jeonseSale === '전세' ? 'd' : 'o'
          };
        }
        if (
          survey.r_selectedPurchase.some(
            (item) => item.optionText === '자녀 양육하기'
          )
        ) {
          spendBody.child_options = {
            kid_years: Number(survey.r_children_age)
          };
        }
        if (
          survey.r_selectedPurchase.some(
            (item) => item.optionText === '기타 목표 달성하기'
          )
        ) {
          spendBody.additional_expenses = {
            amount: Math.floor(Number(survey.r_etc_goal_money) / 1000),
            additional_expense_age:
              Number(survey.r_age) + Number(survey.r_marriageYear)
          };
        }
        fetchOriginChartData(spendBody);
      }
    }
  }, [r_idx]);

  useEffect(() => {
    if (survey.r_chartNoSpend.ages) {
      fetchReportData(survey.r_chartNoSpend, 'r_chartNoSpendReport');
    }
  }, [survey.r_chartNoSpend]);

  useEffect(() => {
    if (survey.r_chartSpend.ages) {
      fetchReportData(survey.r_chartSpend, 'r_chartSpendReport');
    }
  }, [survey.r_chartSpend]);

  useEffect(() => {
    if (overlayRef.current) {
      if (isRecommendationPopupOpen || isServiceUnavailablePopupOpen) {
        // 팝업이 열릴 때 외부 이벤트 작동 차단
        overlayRef.current.style.pointerEvents = 'none';
      } else {
        overlayRef.current.style.pointerEvents = 'auto';
      }
    }
  }, [isRecommendationPopupOpen, isServiceUnavailablePopupOpen]);

  return (
    <div className="w-full max-w-md bg-white">
      <div className="chatbot-overlay" ref={overlayRef}>
        <div className="chatbot-window">
          <Header />
          <MessageWindow />
          <Footer />
          {survey.r_isCarListVisible && <CarList />}
          {survey.r_isApartmentListVisible && <ApartmentList />}
          {isRecommendationPopupOpen && (
            <AlertModal
              onClick={handleRecommendation}
              onClose={handleNoRecommendation}
              message={`완성한 배우자 프로필과 유사한 사람을
                여러 명 찾았어요! 추천 받으시겠어요?`}
              buttonText="추천 받으러 가기"
              subButtonText="아니오, 괜찮습니다."
              imgUrl={SPOUSE_IMG_URL}
            />
          )}
          {isServiceUnavailablePopupOpen && (
            <AlertModal
              onClick={toggleServiceUnavailablePopup}
              message={
                <>
                  <strong>현재 해당 서비스는 준비 중입니다!!</strong>
                  <p>
                    곧 다양한 배우자 추천을 받아보실 수 있도록 준비 중이니
                    조금만 기다려 주세요!
                  </p>
                </>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatbotPage;
