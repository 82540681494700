import React, { useState, useEffect, useMemo } from 'react';
import ProgressBar from './ProgressBar';

const SCREEN_SWITCH_INTERVAL = 2500; // 2.5 seconds
const DEFAULT_TOTAL_DURATION = 10000; // Default total duration is 10 seconds

const SimulationLoading = ({
  showTimerComment = false,
  showPercentage = true,
  totalDuration = DEFAULT_TOTAL_DURATION
}) => {
  const [screenIndex, setScreenIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const screens = useMemo(
    () => [
      {
        title: '시뮬레이션 결과',
        img: '/picture/icons/card01.png',
        description:
          '100세까지의 자산 흐름을 예측합니다. 예측한 자산 흐름은 현재가치로 환산한 순자산입니다.'
      },
      {
        title: '자산 흐름 설정',
        img: '/picture/icons/card02.png',
        description:
          '결혼을 안 했을 경우의 자산 흐름도 비교하고 소득과 지출을 수정해 다양한 시뮬레이션을 해보세요.'
      },
      {
        title: '목표 기반 자산 흐름',
        img: '/picture/icons/card03.png',
        description:
          '시뮬레이션 결과를 보고 목표가 달라지셨나요? 목표 나이과 금액을 수정해 새로운 결과를 확인해보세요!'
      },
      {
        title: '결과 보고서',
        img: '/picture/icons/card04.png',
        description:
          '마지막으로, 다양한 시뮬레이션 결과를 반영한 요약 보고서까지 받아보실 수 있으니, 끝까지 확인 부탁드립니다!'
      }
    ],
    []
  );

  const numScreens = screens.length;

  // 2.5초마다 화면 전환
  useEffect(() => {
    const interval = setInterval(() => {
      setScreenIndex((prevIndex) => (prevIndex + 1) % numScreens);
    }, SCREEN_SWITCH_INTERVAL);

    return () => clearInterval(interval);
  }, [numScreens]);

  // progress를 0%에서 100%까지 totalDuration초 동안 증가시키는 로직
  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          return 100;
        }
        return prev + 100 / (totalDuration / 250);
      });
    }, 250); // Update progress every 0.25 seconds
    return () => clearInterval(progressInterval);
  }, [totalDuration]);

  const { title, img, description } = screens[screenIndex];

  return (
    <div className="fixed w-full max-w-md bg-white left-1/2 transform -translate-x-1/2 inset-0 z-50 flex flex-col items-center justify-between min-h-screen bg-gray-50 text-gray-800 p-6 overflow-y-auto">
      <div className="flex flex-col items-center mt-[210px] px-16">
        <div className="w-40 h-40 flex items-center justify-center mb-[26px]">
          <img
            src={img}
            alt="시뮬레이션 차트 예시"
            className="object-contain w-full h-full"
          />
        </div>
        <h2 className="text-base font-semibold mb-[10px] text-grayDark3">
          {title}
        </h2>
        <p className="text-center text-sm leading-relaxed text-grayDark3">
          {description}
        </p>
      </div>

      <div className="w-[160px] mb-8">
        <ProgressBar
          progress={progress}
          color="#FF6319"
          trailColor="#FDEAE8"
          showTimerComment={showTimerComment}
          showPercentage={showPercentage}
        />
      </div>
    </div>
  );
};

export default SimulationLoading;
