import React from 'react';
import { useNavigate } from 'react-router-dom';
import LeftArrowIcon from '../icon/LeftArrowIcon';

const BackHeader = ({ goToPrev }) => {
  const navigate = useNavigate();

  return (
    <header className="relative left-[-3px] h-[42px]">
      <button
        onClick={() => (goToPrev ? goToPrev() : navigate(-1))}
        aria-label="뒤로 가기"
      >
        <LeftArrowIcon className="text-black" />
      </button>
    </header>
  );
};

export default BackHeader;
