const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className="text-black"
    >
      <path
        d="M8.62156 9.04C8.43888 9.15257 8.22053 9.20872 7.99934 9.2C7.77816 9.20872 7.5598 9.15257 7.37712 9.04L2.66602 6.76001V10.8C2.66602 11.1183 2.80649 11.4235 3.05654 11.6485C3.30659 11.8736 3.64573 12 3.99935 12H11.9993C12.353 12 12.6921 11.8736 12.9421 11.6485C13.1922 11.4235 13.3327 11.1183 13.3327 10.8V6.76001L8.62156 9.04Z"
        fill="currentColor"
      />
      <path
        d="M11.9993 4H3.99935C3.64573 4 3.30659 4.12643 3.05655 4.35147C2.8065 4.57651 2.66602 4.88174 2.66602 5.2V5.6C2.6656 5.67213 2.68582 5.74314 2.72474 5.80619C2.76366 5.86925 2.81997 5.92222 2.88824 5.96L7.77713 8.35999C7.84537 8.39233 7.92254 8.40622 7.99935 8.39999C8.07616 8.40622 8.15333 8.39233 8.22157 8.35999L13.1105 5.96C13.1787 5.92222 13.235 5.86925 13.274 5.80619C13.3129 5.74314 13.3331 5.67213 13.3327 5.6V5.2C13.3327 4.88174 13.1922 4.57651 12.9422 4.35147C12.6921 4.12643 12.353 4 11.9993 4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EmailIcon;
