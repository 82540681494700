import { useRecoilState } from 'recoil';
import { surveyState } from '../recoil/atoms';

export const useUndoQuestion = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);

  const undoQuestion = async () => {
    let finberryCount = 0;
    let nonCommentQuestionCount = 0;
    let newIdx = survey.r_idx;
    const allQuestions = survey.r_allQuestions;

    setSurvey((prevSurvey) => {
      const messages = prevSurvey.r_messages;
      let newMessages = [...messages];
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i].role === 'finberry') {
          finberryCount++;
          if (finberryCount === 2) {
            newMessages = messages.slice(0, i);
            break;
          }
        }
      }
      return {
        ...prevSurvey,
        r_messages: newMessages
      };
    });
    for (let i = survey.r_idx; i >= 0; i--) {
      if (allQuestions[i].questionType !== 'comment') {
        nonCommentQuestionCount++;
        if (nonCommentQuestionCount === 2) {
          newIdx = i;
          break;
        }
      }
    }
    setSurvey((prevSurvey) => {
      return {
        ...prevSurvey,
        r_idx: newIdx - 1
      };
    });
  };
  return undoQuestion;
};
