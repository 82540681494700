import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { config } from '../config';
import { useHandleMultipleAnswerSave } from '../hook/useHandleMultipleAnswerSave';
import { useHandleRemoveOption } from '../hook/useHandleRemoveOption';
import { surveyState } from '../recoil/atoms';
import '../style/TextInput.css';
// import { saveAnswer } from '../util/saveAnswer';
import { useSaveAnswer } from '../hook/useSaveAnswer';
import SendMessageIcon from './icon/SendMessageIcon';

export const TextInput = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const { r_sessionId, r_userId, r_surveyId, r_allQuestions, r_idx } = survey;
  const currentQuestion =
    r_allQuestions && r_allQuestions.length > 0 && r_allQuestions[r_idx];
  const [input, setInput] = useState('');
  const saveAnswer = useSaveAnswer();
  const handleRemoveOption = useHandleRemoveOption();
  const handleMultipleAnswerSave = useHandleMultipleAnswerSave();
  const handleSubmit = (e) => {
    e.preventDefault();
    setInput('');
    if (currentQuestion.questionType === 'MULTIPLE_ANSWER') {
      handleMultipleAnswerSave();
    } else {
      saveAnswer(currentQuestion.questionId, input, null, r_idx);
    }
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };
  const selectedOptions =
    currentQuestion?.questionId === config.PURCHASE_IDX
      ? survey.r_selectedPurchase
      : currentQuestion?.questionId === config.SELF_DEBT_IDX
        ? survey.r_selectedSelfDebt
        : currentQuestion?.questionId === config.SPOUSE_DEBT_IDX
          ? survey.r_selectedSpouseDebt
          : [];

  const isSubmitActive = () => {
    const isMultipleAnswer =
      currentQuestion?.questionType === 'MULTIPLE_ANSWER';
    return isMultipleAnswer && selectedOptions.length > 0;
  };
  return (
    currentQuestion && (
      <form onSubmit={handleSubmit} className="text-input-container">
        {Array.isArray(selectedOptions) &&
        selectedOptions.length > 0 &&
        currentQuestion.questionType === 'MULTIPLE_ANSWER' ? (
          <div className="multiple-answer-option-container">
            {selectedOptions.map((option) => (
              <div key={option.id} className="multiple-answer-option">
                <span>{option.optionText}</span>
                <button
                  type="button"
                  onClick={
                    currentQuestion.questionType === 'MULTIPLE_ANSWER'
                      ? () => handleRemoveOption(option)
                      : () => {}
                  }
                  className="multiple-answer-option-button"
                >
                  x
                </button>
              </div>
            ))}
          </div>
        ) : (
          <input
            type="text"
            value={input}
            onChange={handleChange}
            placeholder="메세지를 입력하세요"
            className="text-input"
          />
        )}
        <button
          type="submit"
          className="text-input-button"
          onClick={handleSubmit}
        >
          <SendMessageIcon
            className={`text-input-button-icon 
                            ${
                              isSubmitActive() ? 'text-orange' : 'text-grayMid2'
                            }`}
          />
        </button>
      </form>
    )
  );
};
