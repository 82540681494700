import { config } from '../config';
import { useSendConfirmCode } from '../hook/useSendConfirmCode';
import { useVerifyConfirmCode } from '../hook/useVerifyConfirmCode';

const TestPage = () => {
  const sendConfirmCode = useSendConfirmCode();
  const verifyConfirmCode = useVerifyConfirmCode();
  return (
    <>
      <br />
      <br />
      <br />
      <a href={config.KAKAO_AUTH_URL}>카카오로 로그인하기(백엔드)</a>
      <br />
      <br />
      <br />
      <a href={config.KAKAO_LOGOUT_URL}>카카오로 로그아웃하기(백엔드)</a>
      <br />
      <br />
      <br />
      <a href={config.KAKAO_UNLINK_URL}>카카오로 연결끊기(백엔드)</a>
      <br />
      <br />
      <br />
      <a href={config.NAVER_AUTH_URL}>네이버로 로그인하기(백엔드)</a>
      <br />
      <br />
      <br />
      <a href={config.NAVER_UNLINK_URL}>네이버로 연결끊기(백엔드)</a>
      <br />
      <br />
      <br />
      <a href={config.GOOGLE_AUTH_URL}>구글로 로그인하기(백엔드)</a>
      <br />
      <br />
      <br />
      <a href={config.GOOGLE_UNLINK_URL}>구글로 연결끊기(백엔드)</a>
      <br />
      <br />
      <br />
      <div>
        <input
          type="text"
          placeholder="전화번호를 입력하세요"
          style={{ padding: '8px', marginRight: '8px' }}
        />
        <button
          style={{ padding: '8px 16px' }}
          onClick={() => {
            const phoneNumber = document.querySelector(
              'input[placeholder="전화번호를 입력하세요"]'
            ).value;
            const res = sendConfirmCode(phoneNumber);
            console.log('res', res);
          }}
        >
          인증번호 전송
        </button>
      </div>
      <br />
      <br />
      <div>
        <input
          type="text"
          placeholder="인증번호를 입력하세요"
          style={{ padding: '8px', marginRight: '8px' }}
        />
        <button
          style={{ padding: '8px 16px' }}
          onClick={() => {
            const phoneNumber = document.querySelector(
              'input[placeholder="전화번호를 입력하세요"]'
            ).value;
            const code = document.querySelector(
              'input[placeholder="인증번호를 입력하세요"]'
            ).value;
            const res = verifyConfirmCode(phoneNumber, code);
            console.log('res', res);
          }}
        >
          인증하기
        </button>
      </div>
    </>
  );
};

export default TestPage;
