import ktorApi from '../api/ktorApi';

export const fetchUserInfo = async () => {
  try {
    const response = await ktorApi.get('/me');

    if (response.status === 200 || response.status === 201) {
      return {
        success: true,
        data: response.data
      };
    } else {
      return {
        success: false,
        error: '유저 정보 가져오기 실패'
      };
    }
  } catch (error) {
    return {
      success: false,
      error: error.response?.data || '유저 정보 가져오는 중 오류 발생'
    };
  }
};
