import { useRecoilValue } from 'recoil';
import { config } from '../config';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import { surveyState } from '../recoil/atoms';
import { useDeleteSelectedOptions } from './useDeleteSelectedOptions';
import { useMoveToNextQuestion } from './useMoveToNextQuestion';
import { useMoveToTheQuestion } from './useMoveToTheQuestion';
import { useSelectedOptionSave } from './useSelectedOptionSave';
export const useHandleMultipleAnswerSave = () => {
  const survey = useRecoilValue(surveyState);
  const { r_idx, r_allQuestions, r_selectedSelfDebt, r_selectedSpouseDebt } =
    survey;
  const currentQuestion =
    r_allQuestions && r_allQuestions.length > 0 && r_allQuestions[r_idx];
  const deleteSelectedOptions = useDeleteSelectedOptions();
  const selectedOptionSave = useSelectedOptionSave();
  const moveToTheQuestion = useMoveToTheQuestion();
  const moveToNextQuestion = useMoveToNextQuestion();
  const setSurveyValue = useSetSurveyValue();

  const handleMultipleAnswerSave = async () => {
    console.log('HHH!!!!:', survey.r_selectedPurchase);
    deleteSelectedOptions();
    selectedOptionSave();
    switch (currentQuestion.questionId) {
      case config.PURCHASE_IDX:
        break;
      case config.SELF_DEBT_IDX:
        if (r_selectedSelfDebt[0].orderIndex === 1) {
          moveToTheQuestion(config.SELF_MORTGAGE_IDX);
        } else if (r_selectedSelfDebt[0].orderIndex === 2) {
          moveToTheQuestion(config.SELF_ETC_DEBT_IDX);
        }
        return;
      case config.SPOUSE_DEBT_IDX:
        if (r_selectedSpouseDebt[0].orderIndex === 1) {
          moveToTheQuestion(config.SPOUSE_MORTGAGE_IDX);
        } else if (r_selectedSpouseDebt[0].orderIndex === 2) {
          moveToTheQuestion(config.SPOUSE_ETC_DEBT_IDX);
        }
        return;
    }
    moveToNextQuestion();
    return;
  };
  return handleMultipleAnswerSave;
};
