import { useRecoilState } from 'recoil';
import { surveyState } from '../recoil/atoms';

export const useDeleteSelectedOptions = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);

  const deleteSelectedOptions = () => {
    setSurvey((prevSurvey) => {
      const updatedMessages = [...prevSurvey.r_messages];
      const lastMessage = { ...updatedMessages[updatedMessages.length - 1] };
      // console.log('updatedMessages: ', updatedMessages);
      // console.log('lastMessage:!!!!!!! ', lastMessage);
      // console.log('lastMessage.content: ', lastMessage.content);
      // console.log('lastMessage.content.options: ', lastMessage.content.options);
      if (lastMessage.content && lastMessage.content.options) {
        console.log('There are options', lastMessage.content.options);
        lastMessage.content = { ...lastMessage.content, options: [] };
      }

      updatedMessages[updatedMessages.length - 1] = lastMessage;
      console.log('updatedMessages: ', updatedMessages);
      return {
        ...prevSurvey,
        r_messages: updatedMessages
      };
    });
  };

  return deleteSelectedOptions;
};
