import '../style/CarListEntry.css';
import { formatKoreanCurrency } from '../util/formatKoreanCurrency';

export const CarListEntry = ({ car, index, selectedCarIdx, handleCarIdx }) => {
  const { title, maker, brand, price_min, price_max, picture_address } = car;
  const isSelected = selectedCarIdx === index;
  return (
    <button
      className="car-container"
      onClick={() => handleCarIdx(index)}
      key={index}
    >
      {/* Dynamically set border and background based on selection */}
      <div
        className={`car-list-item ${isSelected ? 'border border-orange rounded-2xl' : ''}`}
      >
        <div
          className={`car-item-number ${isSelected ? 'bg-orange' : 'bg-grayLight'}`}
        >
          <span className={`${isSelected ? 'text-white' : 'text-grayDark'}`}>
            {index + 1}
          </span>
        </div>

        {/* Car details */}
        <div className="car-item-content">
          <div className="px-2 mr-6">
            <img
              src={`/picture/cars/${encodeURIComponent(picture_address)}`}
              alt={title}
              className="car-image"
            />
          </div>
          <div className="car-details">
            <p className="car-brand">{`${maker} - ${brand}`}</p>
            <p className="car-name">{title}</p>
            <p className="car-price">
              {`${formatKoreanCurrency(price_min)} ~ ${formatKoreanCurrency(price_max)}원`}
            </p>
          </div>
        </div>
      </div>
    </button>
  );
};
