const KakaoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_1118_811)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00002 0.533203C3.58149 0.533203 0 3.30028 0 6.71301C0 8.83545 1.38525 10.7065 3.49469 11.8194L2.60714 15.0616C2.52872 15.3481 2.85637 15.5765 3.10797 15.4105L6.99853 12.8427C7.32685 12.8744 7.66051 12.8929 8.00002 12.8929C12.4182 12.8929 15.9999 10.1259 15.9999 6.71301C15.9999 3.30028 12.4182 0.533203 8.00002 0.533203Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1118_811">
          <rect width="15.9999" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KakaoIcon;
