import { Link, useNavigate } from 'react-router-dom';
import { useUndoQuestion } from '../../hook/useUndoQuestion';
import LeftArrowIcon from '../icon/LeftArrowIcon';
import '../../style/Header.css';

export const Header = () => {
  const undoQuestion = useUndoQuestion();
  const navigate = useNavigate();

  return (
    <div className="header">
      <button
        onClick={() => navigate('/')}
        className="header-button"
        aria-label="뒤로 가기"
      >
        <LeftArrowIcon />
      </button>
      <Link to="/" className="flex" aria-label="홈으로 이동">
        <img
          src="/picture/finberry.png"
          alt="logo"
          className="h-[21px] w-auto cursor-pointer"
        />
      </Link>
    </div>
  );
};
