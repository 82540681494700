const SendMessageIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M20 9.99948L0 20L2.1777 11.0393L10.8885 9.99844L2.1777 8.74317L0 0L20 9.99948Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SendMessageIcon;
