import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '../../recoil/userAtom';

const CheckoutPage = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const hasPurchasedItems = user.purchaseItems !== '[]';

  useEffect(() => {
    if (hasPurchasedItems) {
      // 결제 한 회원
      navigate('/chat');
    } else {
      navigate('/checkout/order');
    }
  }, [hasPurchasedItems]);

  return <div></div>;
};

export default CheckoutPage;
