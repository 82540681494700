import axios from 'axios';
import qs from 'qs';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { kakaoAccessTokenAtom } from '../recoil/kakaoAtoms';

export const KakaoAuth = () => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useRecoilState(kakaoAccessTokenAtom);
  useEffect(() => {
    console.log('HHHHHHHHH');
    const getToken = async () => {
      const params = new URL(document.URL).searchParams;
      const code = params.get('code');
      const payload = qs.stringify({
        grant_type: 'authorization_code',
        client_id: process.env.REACT_APP_KAKAO_REST_API_KEY,
        redirect_uri: process.env.REACT_APP_KAKAO_REDIRECT_URI,
        code: code,
        client_secret: process.env.REACT_APP_KAKAO_CLIENT_SECRET
      });
      try {
        const res = await axios.post(
          'https://kauth.kakao.com/oauth/token',
          payload,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            withCredentials: false
          }
        );
        setAccessToken(res.data.access_token);
        // Navigate to the home page after successful login
        navigate('/test/kakao');
      } catch (err) {
        console.error('Error fetching Kakao token:', err);
      }
    };

    getToken();
  }, []); // Include `navigate` in the dependency array

  return <div>로그인 진행중입니다..</div>;
};
