import { useRecoilValue } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';

export const useShouldDisplayOption = () => {
  const survey = useRecoilValue(surveyState);
  const shouldDisplayOption = (newOption) => {
    switch (newOption.questionId) {
      case config.PURCHASE_IDX:
        return !survey.r_selectedPurchase?.some(
          (option) => option.orderIndex === newOption.orderIndex
        );
      case config.SELF_DEBT_IDX:
        return !survey.r_selectedSelfDebt?.some(
          (option) => option.orderIndex === newOption.orderIndex
        );
      case config.SPOUSE_DEBT_IDX:
        return !survey.r_selectedSpouseDebt?.some(
          (option) => option.orderIndex === newOption.orderIndex
        );
      default:
        return true;
    }
  };
  return shouldDisplayOption;
};
