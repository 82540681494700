import ktorApi from '../api/ktorApi';
export const useSendConfirmCode = () => {
  const sendConfirmCode = async (phoneNumber) => {
    console.log('sendConfirmCode', phoneNumber);
    const response = await ktorApi.post('/aligo/send', {
      phoneNum: phoneNumber
    });
    return response.data;
  };
  return sendConfirmCode;
};
