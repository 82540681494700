import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStep } from '../../hook/useStep';
import PolicyForm from './PolicyForm';
import NicknameForm from './NicknameForm';
import MobilePhoneForm from './MobilePhoneForm';
import PasswordForm from './PasswordForm';
import { signup } from '../../util/signup';
import { signupOAuth } from '../../util/signupOAuth';

const STEPS = ['이용약관', '닉네임', '휴대폰 번호', '비밀번호'];

const SignupForm = () => {
  const { currentStep, nextStep, prevStep } = useStep(0, STEPS.length);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    userId: '',
    fullName: '',
    phoneNum: '',
    plainPassword: '',
    termsAgreement: false
  });
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

  useEffect(() => {
    if (isReadyToSubmit) {
      handleSubmit(userData);
      setIsReadyToSubmit(false);
    }
  }, [isReadyToSubmit, userData]);

  const handleSubmit = async () => {
    const result = await signup(userData);

    if (result.success) {
      alert('회원가입이 성공적으로 완료되었습니다.');
      navigate('/user/login/mobile');
    } else {
      alert(`${result.error}`);
      window.location.reload();
    }
  };

  const handleOAuthSignup = async (email, termsAgreement) => {
    const userData = {
      email,
      termsAgreement
    };

    const result = await signupOAuth(userData);
    if (result.success) {
      alert('로그인이 성공적으로 완료되었습니다. 메인화면으로 이동합니다.');
      navigate('/');
    } else {
      alert(`${result.error}`);
      window.location.reload();
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PolicyForm
            nextStep={nextStep}
            setUserData={setUserData}
            handleOAuthSignup={handleOAuthSignup}
          />
        );
      case 1:
        return (
          <NicknameForm
            nextStep={nextStep}
            prevStep={prevStep}
            setUserData={setUserData}
          />
        );
      case 2:
        return (
          <MobilePhoneForm
            nextStep={nextStep}
            prevStep={prevStep}
            setUserData={setUserData}
          />
        );
      case 3:
        return (
          <PasswordForm
            nextStep={nextStep}
            prevStep={prevStep}
            setUserData={setUserData}
            setIsReadyToSubmit={setIsReadyToSubmit}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="h-screen w-full max-w-md">
      {renderStepContent(currentStep)}
    </div>
  );
};

export default SignupForm;
