import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isAuthenticatedState } from '../recoil/userAtom';
import { KakaoAuth } from '../util/kakaoAuth';
import { useFetchUser } from '../hook/useFetchUser';
import { routeConfig } from './routeConfig';
import AdminRoute from './AdminRoute';
import ChatRoute from './ChatbotRoute';
import ErrorRoute from './ErrorRoute';
import HomeRoute from './HomeRoute';
import TestRoute from './TestRoute';
import UserRoute from './UserRoute';
import CheckoutRoute from './CheckoutRoute';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const RoutesConfig = () => {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const { initializeUser } = useFetchUser();

  useEffect(() => {
    initializeUser(); // 새로고침 시 유저 정보 복원
  }, []);

  return (
    <Routes>
      <Route path="/" element={<HomeRoute />} />
      <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
        <Route path="user/*" element={<UserRoute />} />
      </Route>
      <Route
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            redirectTo={routeConfig.LOGIN_ROUTE}
          />
        }
      >
        <Route path="chat/*" element={<ChatRoute />} />
        <Route path="admin/*" element={<AdminRoute />} />
        <Route path="checkout/*" element={<CheckoutRoute />} />
      </Route>
      <Route path="test/*" element={<TestRoute />} />
      <Route path="oauth/*" element={<KakaoAuth />} />
      <Route path="*" element={<ErrorRoute />} />
    </Routes>
  );
};

export default RoutesConfig;
