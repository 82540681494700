import React, { useState } from 'react';
import BackHeader from '../../component/header/BackHeader';
import PrimaryButton from '../../component/button/PrimaryButton';

const NicknameForm = ({ nextStep, prevStep, setUserData }) => {
  const [inputValue, setInputValue] = useState('');
  const maxLength = 10;
  const minLength = 2;

  const handleChange = (e) => {
    const value = e.target.value;
    // 한글, 영문, 숫자만 허용
    const regex = /^[ㄱ-ㅎ가-힣a-zA-Z0-9]*$/;
    if (regex.test(value)) {
      setInputValue(value.slice(0, maxLength)); // 최대 10글자 제한
    }
  };

  const isValidLength = inputValue.length >= minLength;

  const onClickConfirm = () => {
    setUserData((prev) => ({ ...prev, fullName: inputValue }));
    nextStep();
  };

  return (
    <div className="flex flex-col h-screen px-4 py-8">
      <BackHeader goToPrev={prevStep} />
      <div className="flex-1">
        <div className="pt-6">
          <h1 className="text-2xl font-bold leading-normal tracking-[-0.48px]">
            닉네임을 입력해 주세요!
          </h1>
          <p className="mt-2 text-sm leading-[normal] text-grayDark2 whitespace-pre-line">
            {`채팅과 보고서에 쓰이는 닉네임이에요.\n프로필 페이지에서 언제든 변경 가능해요.`}
          </p>
        </div>
        <div className="flex flex-col py-4 w-full">
          <input
            type="text"
            inputMode="text"
            value={inputValue}
            onChange={handleChange}
            placeholder="2글자 이상 10글자 이내 한글,영문,숫자만 입력 가능"
            className={`outline-none p-3 text-[15px] font-semibold text-grayDark3 placeholder-grayMid w-full rounded-lg border justify-start items-center gap-3 inline-flex ${
              inputValue ? 'border-orange' : 'border-grayMid2'
            }`}
          />
          <span className="text-sm text-grayMid mt-1 ml-3">{`${inputValue.length}/${maxLength}`}</span>
        </div>
      </div>

      <div className="sticky w-full py-2">
        <PrimaryButton
          onClick={onClickConfirm}
          disabled={!isValidLength}
          className="outline-none rounded-full py-[8px] text-[15px] font-medium"
        >
          확인
        </PrimaryButton>
      </div>
    </div>
  );
};

export default NicknameForm;
