import { useRecoilState } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';

export const useSelectedOptionSort = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const selectedOptionSort = (option) => {
    switch (option.questionId) {
      case config.PURCHASE_IDX:
        setSurvey((prevSurvey) => {
          const updatedPurchase = [
            ...prevSurvey.r_selectedPurchase,
            option
          ].sort((a, b) => a.orderIndex - b.orderIndex);
          return {
            ...prevSurvey,
            r_selectedPurchase: updatedPurchase
          };
        });
        break;
      case config.SELF_DEBT_IDX:
        setSurvey((prevSurvey) => {
          const updatedSelfDebt = [
            ...prevSurvey.r_selectedSelfDebt,
            option
          ].sort((a, b) => a.orderIndex - b.orderIndex);

          return {
            ...prevSurvey,
            r_selectedSelfDebt: updatedSelfDebt
          };
        });
        break;
      case config.SPOUSE_DEBT_IDX:
        setSurvey((prevSurvey) => {
          const updatedSpouseDebt = [
            ...prevSurvey.r_selectedSpouseDebt,
            option
          ].sort((a, b) => a.orderIndex - b.orderIndex);
          return {
            ...prevSurvey,
            r_selectedSpouseDebt: updatedSpouseDebt
          };
        });
        break;
      default:
        break;
    }
  };

  return selectedOptionSort;
};
