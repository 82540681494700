import { useRecoilState } from 'recoil';
import { userState } from '../recoil/userAtom';
import { fetchUserInfo } from '../util/fetchUserInfo';
import { useSetSurveyValue } from './useSetSurveyValue';

export const useFetchUser = () => {
  const [user, setUser] = useRecoilState(userState);
  const setSurveyValue = useSetSurveyValue();

  const initializeUser = async () => {
    // 1. 로컬 스토리지 복원
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      console.log('storedUser: ', storedUser);
      setUser(storedUser); // 임시로 복원
      setSurveyValue('r_userId', storedUser.id);
    }
    // 2. /me API 호출로 최신 정보 동기화
    try {
      const result = await fetchUserInfo(); // /me API 호출
      if (result.success) {
        setUser(result.data);
        localStorage.setItem('user', JSON.stringify(result.data));
        setSurveyValue('r_userId', result.data.id);
      } else {
        setUser(null);
        localStorage.removeItem('user'); // 유저 정보 초기화
        setSurveyValue('r_userId', 0);
      }
    } catch (error) {
      console.error('유저 정보 초기화 실패:', error);
      setUser(null);
      localStorage.removeItem('user');
      setSurveyValue('r_userId', 0);
    }
  };

  return { user, initializeUser };
};
