import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
// import { saveAnswer } from '../util/saveAnswer';
import { useAddMessage } from './useAddMessage';
import { useAddUserMessage } from './useAddUserMessage';
import { useDeleteSelectedOptions } from './useDeleteSelectedOptions';
import { useFetchApartmentList } from './useFetchApartmentList';
import { useMoveToNextQuestion } from './useMoveToNextQuestion';
import { useMoveToTheQuestion } from './useMoveToTheQuestion';
import { useSaveAnswer } from './useSaveAnswer';
import { useSetSurveyValue } from './useSetSurveyValue';
import { useSkipValueSet } from './useSkipValueSet';

export const useHandleMultipleChoice = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const { r_selectedPurchase } = survey;
  const deleteSelectedOptions = useDeleteSelectedOptions();
  const skipValueSet = useSkipValueSet();
  const addUserMessage = useAddUserMessage();
  const addMessage = useAddMessage();
  const moveToNextQuestion = useMoveToNextQuestion();
  const moveToTheQuestion = useMoveToTheQuestion();
  const setSurveyValue = useSetSurveyValue();
  const fetchApartmentList = useFetchApartmentList();
  const navigate = useNavigate();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const saveAnswer = useSaveAnswer();
  const handleMultipleChoice = async (option) => {
    console.log('option: ', option);
    deleteSelectedOptions();
    addUserMessage(option);
    if (
      option.optionSubText &&
      option.optionSubText !== null &&
      option.optionSubText !== ''
    ) {
      let subTextArray;
      try {
        subTextArray = JSON.parse(option.optionSubText);
      } catch (error) {
        console.error('Error parsing optionSubText:', error);
        subTextArray = [option.optionSubText];
      }
      const addMessagesWithDelay = async (subTextArray) => {
        for (const text of subTextArray) {
          addMessage({ questionText: text });
          await delay(config.MESSAGE_BUBBLE_DELAY); // 5-second delay before the next message
        }
      };
      addMessagesWithDelay(subTextArray);
      if (
        option.questionId === config.SELF_CASH_ASSET_IDX &&
        option.orderIndex === 1
      ) {
        setSurveyValue('r_isCashExplained', true);
      }
      if (option.questionId === config.SELF_INVESTMENT_ASSET_IDX) {
        setSurveyValue('r_isInvestmentExplained', true);
      }
      moveToNextQuestion();
      return;
    } else {
      if (option.optionText === '넘어갈게요') {
        skipValueSet(option.questionId);
        saveAnswer(option.questionId, '--', null);
        moveToTheQuestion(option.nextQuestionId);
        return;
      }
      if (option.optionText === '직접 입력할게요') {
        addUserMessage({ optionText: '직접 입력할게요' });
        moveToNextQuestion();
        setSurvey((prevSurvey) => ({
          ...prevSurvey,
          r_messages: [...prevSurvey.r_messages.slice(0, -1)]
        }));
        return;
      }
      if (option.optionText === '계속할래요') {
        if (r_selectedPurchase[0]?.orderIndex === 1) {
          moveToNextQuestion();
          return;
        } else if (r_selectedPurchase[0]?.orderIndex === 2) {
          moveToTheQuestion(config.APARTMENT_SURVEY_IDX);
          return;
        } else if (r_selectedPurchase[0]?.orderIndex === 3) {
          moveToTheQuestion(config.CHILDREN_SURVEY_IDX);
          return;
        } else if (r_selectedPurchase[0]?.orderIndex === 4) {
          moveToTheQuestion(config.EXTRA_GOAL_SURVEY_IDX);
          return;
        }
        //테스트 용도 => 추후 삭제
        else {
          moveToNextQuestion();
          return;
        }
      }
      if (option.optionText === '네, 정확합니다. ') {
        moveToNextQuestion();
        return;
      }
      if (option.optionText === '미래 배우자 설정 다시 할래요') {
        moveToTheQuestion(option.nextQuestionId);
        return;
      }
      if (option.optionText === '아니요, 수정하고 싶어요') {
        moveToTheQuestion(config.GENDER_IDX);
        return;
      }
      // if (option.optionText === '시뮬레이션으로 넘어갈래요') {
      //   moveToTheQuestion(config.BASIC_SURVEY_END_IDX);
      //   return;
      // }
      if (option.optionText === '그만둘래요') {
        addMessage({ questionText: '수고 하셨습니다. 다음에 또 만나요!' });
        return;
      }

      if (option.questionId === config.SPOUSE_NAMECARD_IDX) {
        if (option.optionText === '내 프로필도 확인해볼래요') {
          moveToNextQuestion();
          return;
        } else {
          moveToTheQuestion(config.SIMULATION_START_IDX);
          return;
        }
      }

      if (option.questionId === config.GENDER_IDX) {
        const isMale = option.optionText?.trim() === '남자';
        setSurveyValue('r_gender', isMale ? 'male' : 'female');
      }
      if (option.questionId === config.APARTMENT_JEONSE_SALE_IDX) {
        setSurveyValue('r_jeonseSale', option.optionText);
      }
      if (option.questionId === config.ADDRESS1_IDX) {
        setSurveyValue('r_apartmentAddress_1', option.optionText);
      }
      if (option.questionId === config.ADDRESS2_IDX) {
        setSurveyValue('r_apartmentAddress_2', option.optionText);
        console.log('address2 idx');
        let result = await fetchApartmentList(option.optionText);
        console.log('result:', result);
        if (result === 0) {
          console.log('Apartment List Found');
          setSurveyValue('r_isApartmentSearchOk', true);
          moveToNextQuestion();
          return;
        } else {
          // alert('조건에 맞는 아파트가 없습니다.');
          console.log('Apartment List Not Found');
          setSurveyValue('r_isApartmentSearchOk', false);
          moveToTheQuestion(config.APARTMENT_PRICE_IDX);
          return;
        }
      }

      if (option.questionId === config.CAR_CONFIRM_IDX) {
        if (option.orderIndex === 1) {
          saveAnswer(option.questionId, survey.r_carName, null);
          if (r_selectedPurchase.some((item) => item.orderIndex === 2)) {
            moveToTheQuestion(config.APARTMENT_SURVEY_IDX);
            return;
          } else if (r_selectedPurchase.some((item) => item.orderIndex === 3)) {
            moveToTheQuestion(config.CHILDREN_SURVEY_IDX);
            return;
          } else if (r_selectedPurchase.some((item) => item.orderIndex === 4)) {
            moveToTheQuestion(config.EXTRA_GOAL_SURVEY_IDX);
            return;
          } else {
            moveToTheQuestion(config.END_IDX);
            return;
          }
        }
        if (option.orderIndex === 2) {
          moveToTheQuestion(config.CAR_SURVEY_IDX);
          return;
        }
      }
      if (option.questionId === config.END_IDX) {
        console.log('end idx');
        setSurveyValue('r_messages', []);
        setSurveyValue('r_idx', 0);
        setSurveyValue('r_allQuestions', []);
        navigate('/chat/simulation');
        return;
      }
      if (option.questionId === config.APARTMENT_CONFIRM_IDX) {
        if (option.orderIndex === 1) {
          saveAnswer(option.questionId, survey.r_carName, null);
          if (r_selectedPurchase.some((item) => item.orderIndex === 3)) {
            moveToTheQuestion(config.CHILDREN_SURVEY_IDX);
            return;
          } else if (r_selectedPurchase.some((item) => item.orderIndex === 4)) {
            console.log('extra goal survey idx!!!!!!!!!!!!!!!!!!!!!!');
            moveToTheQuestion(config.EXTRA_GOAL_SURVEY_IDX);
            return;
          } else {
            moveToTheQuestion(config.END_IDX);
            return;
          }
        } else if (option.orderIndex === 2) {
          moveToTheQuestion(config.APARTMENT_NAMECARD_IDX);
          return;
        }
      }

      if (option.questionId === config.EXTRA_GOAL_CONFIRM_IDX) {
        if (option.orderIndex === 1) {
          saveAnswer(
            config.EXTRA_GOAL_SURVEY_IDX,
            survey.r_etc_goal_money,
            null
          );
          saveAnswer(config.EXTRA_GOAL_YEAR_IDX, survey.r_etc_goal_year, null);
          moveToNextQuestion();
          return;
        }
        if (option.orderIndex === 2) {
          moveToTheQuestion(config.EXTRA_GOAL_SURVEY_IDX);
          return;
        }
        if (option.orderIndex === 3) {
          moveToTheQuestion(config.EXTRA_GOAL_YEAR_IDX);
          return;
        }
      }

      saveAnswer(option.questionId, option.optionText, null);
      moveToNextQuestion();
      return;
    }
  };
  return handleMultipleChoice;
};
