const BackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      className="text-black"
    >
      <path
        d="M6.19629 14.9485L24.6414 14.9485C26.9384 14.9485 28.8004 16.8106 28.8004 19.1075L28.8004 20.6332C28.8004 22.9302 26.9384 24.7922 24.6414 24.7922L10.2067 24.7922"
        stroke="currentColor"
        strokeWidth="2.08134"
        strokeLinejoin="round"
      />
      <path
        d="M9.84375 10.5754C8.13521 12.284 7.17729 13.2419 5.46875 14.9504L9.84375 19.3254"
        stroke="currentColor"
        strokeWidth="2.08134"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BackIcon;
