import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BackHeader from '../../component/header/BackHeader';
import PrimaryButton from '../../component/button/PrimaryButton';
import RightArrowIcon from '../../component/icon/RightArrowIcon';
import PolicyDetailModal from './PolicyDetailModal';
import '../../style/UserPolicyPage.css';

const DEFAULT_POLICY = [
  {
    id: 10001,
    label: '핀베리 서비스 이용약관',
    isRequired: true,
    isChecked: false,
    path: '/policy/user-policy-10001.txt'
  },
  {
    id: 10002,
    label: '개인정보 수집 및 이용 동의',
    isRequired: true,
    isChecked: false,
    path: '/policy/user-policy-10001.txt'
  },
  {
    id: 10003,
    label: '광고성 정보 수신 동의',
    isRequired: false,
    isChecked: false,
    path: '/policy/user-policy-10001.txt'
  }
];

const PolicyForm = ({ nextStep, setUserData, handleOAuthSignup }) => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const [policyList, setPolicyList] = useState(DEFAULT_POLICY);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileContent, setFileContent] = useState('');

  // 파일 읽기 및 모달 열기
  const openPolicyDetail = async (id) => {
    const path = policyList.find((policy) => policy.id === id).path;
    try {
      const response = await fetch(path);
      if (!response.ok) {
        throw new Error('Failed to fetch the text file.');
      }
      const text = await response.text();
      setFileContent(text);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching the txt file:', error);
      setFileContent('');
      setIsModalOpen(true);
    }
  };

  const closePolicyDetail = () => {
    setIsModalOpen(false);
    setFileContent('');
  };

  const handleSelection = (id) => {
    setPolicyList((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      )
    );
  };

  const handleAllSelection = (e) => {
    setIsAllChecked(e.target.checked);
    setPolicyList((prev) =>
      prev.map((item) => ({ ...item, isChecked: e.target.checked }))
    );
  };

  const isAgreeButtonDisabled = policyList
    .filter((policy) => policy.isRequired)
    .some((policy) => !policy.isChecked);

  const onClickAgreeButton = () => {
    const termsAgreement = policyList.find(
      (policy) => policy.id === 10003
    ).isChecked;
    setUserData((prev) => {
      return { ...prev, termsAgreement };
    });

    setTimeout(() => {
      if (email) {
        handleOAuthSignup(email, termsAgreement);
        return;
      }
      nextStep();
    }, 0);
  };

  useEffect(() => {
    if (policyList.every((item) => item.isChecked)) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [policyList]);

  if (isModalOpen) {
    return (
      <PolicyDetailModal close={closePolicyDetail} fileContent={fileContent} />
    );
  }

  return (
    <div className="flex flex-col h-screen px-4 py-8">
      <BackHeader />
      <div className="flex-1">
        <div className="pt-6">
          <div className="w-auto h-8 mb-1">
            <img
              src="/picture/finberry.png"
              alt="finberry"
              className="w-auto h-full"
            />
          </div>
          <h1 className="text-2xl font-bold leading-normal tracking-[-0.48px]">
            약관에 동의해주세요.
          </h1>
          <p className="mt-2 text-base leading-[normal] tracking-[-0.32px] whitespace-pre-line">
            {`개인정보와 서비스 이용관리를 보호하고\n핀베리 서비스를 잘 이용하기 위함이에요.`}
          </p>
          <div className="pt-[88px]">
            <div className="p-4 w-full bg-grayLight2 rounded-lg justify-start items-start gap-4 flex">
              <label className="round-checkbox mb-0">
                <input
                  type="checkbox"
                  checked={isAllChecked}
                  onChange={(e) => handleAllSelection(e)}
                  className="round-checkbox-input"
                />
                <span className="text-base font-bold leading-[normal]">
                  아래 약관 모두 동의
                </span>
              </label>
            </div>
            <div className="flex flex-col gap-6 pt-6 px-4">
              {policyList.map((item, idx) => (
                <div key={item.id} className="round-checkbox justify-between">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id={`checkbox-${item.id}`}
                      checked={item.isChecked}
                      onChange={() => handleSelection(item.id)}
                      className="round-checkbox-input"
                    />
                    <label htmlFor={`checkbox-${item.id}`}>
                      <span className="text-grayDark3 font-normal">{`${item.isRequired ? '(필수)' : '(선택)'} ${item.label}`}</span>
                    </label>
                  </div>
                  <button
                    onClick={() => {
                      openPolicyDetail(item.id);
                    }}
                  >
                    <RightArrowIcon className="cursor-pointer" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="sticky w-full px-4 py-2">
        <PrimaryButton
          onClick={onClickAgreeButton}
          disabled={isAgreeButtonDisabled}
          className="rounded-full"
        >
          동의
        </PrimaryButton>
      </div>
    </div>
  );
};

export default PolicyForm;
