import api from '../api/api';
import { config } from '../config';

export const addSurvey = async (survey) => {
  try {
    const response = await api.post('/admin/surveys', survey);
  } catch (error) {
    console.error('Error adding survey:', error);
  }
};
