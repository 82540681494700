import { useRecoilState } from 'recoil';
import { surveyState } from '../recoil/atoms';

export const useAddLastMessageOption = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const { r_surveyId, r_idx } = survey;
  const addLastMessageOption = (newList) => {
    const newOptions = newList.map((option, idx) => ({
      surveyId: r_surveyId,
      questionId: r_idx + 1,
      orderIndex: idx + 1,
      optionText: option,
      id: idx
    }));
    console.log('newOptions: ', newOptions);
    setSurvey((prevSurvey) => {
      const lastMessageIndex = prevSurvey.r_messages.length - 1;
      const updatedMessages = [...prevSurvey.r_messages];
      updatedMessages[lastMessageIndex] = {
        ...updatedMessages[lastMessageIndex],
        content: {
          ...updatedMessages[lastMessageIndex].content,
          options: newOptions
        }
      };
      return {
        ...prevSurvey,
        r_messages: updatedMessages
      };
    });
  };

  return addLastMessageOption;
};
