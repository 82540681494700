import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import ChatbotPage from '../page/ChatbotPage';
import ChartPage from '../page/ChartPage';
import ChatbotPage from '../page/ChatbotPage';
import SimulationPage from '../page/SimulationPage';
import SurveyPopupPage from '../page/SurveyPopupPage';

const ChatRoutes = () => {
  return (
    <Routes>
      <Route index element={<ChatbotPage />} />
      <Route path=":topic" element={<ChatbotPage />} />
      <Route path="/simulation" element={<SimulationPage />} />
      <Route path="/chart" element={<ChartPage />} />
      <Route path="/survey" element={<SurveyPopupPage />} />
    </Routes>
  );
};

export default ChatRoutes;
