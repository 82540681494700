import React from 'react';
import { Route, Routes } from 'react-router-dom';
import KakaoInfoPage from '../page/KakaoInfoPage';
import TestPage from '../page/TestPage';

const TestRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<TestPage />} />
      <Route path="/kakao" element={<KakaoInfoPage />} />
    </Routes>
  );
};

export default TestRoute;
