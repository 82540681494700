import apiClient from './apiClient';

const api = {
  // GET 요청
  get: async (url, params = {}) => {
    try {
      const response = await apiClient.get(url, { params });
      return response;
    } catch (error) {
      throw error;
    }
  },

  // POST 요청
  post: async (url, data = {}, headers = {}) => {
    try {
      const response = await apiClient.post(url, data, {
        headers
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  // PUT 요청
  put: async (url, data = {}) => {
    try {
      const response = await apiClient.put(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  // DELETE 요청
  delete: async (url) => {
    try {
      const response = await apiClient.delete(url);
      return response;
    } catch (error) {
      throw error;
    }
  }
};

export default api;
