import { useRecoilState } from 'recoil';
import { surveyState } from '../recoil/atoms';

export const useSetSurveyValue = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);

  const setSurveyValue = (key, value) => {
    setSurvey((prevSurvey) => ({ ...prevSurvey, [key]: value }));
  };

  return setSurveyValue;
};
