// src/routes/HomeRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../page/user/LoginForm';
import Signup from '../page/user/SignupForm';
import MobileLogin from '../page/user/MobileLoginPage';

const UserRoute = () => {
  return (
    <Routes>
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/mobile" element={<MobileLogin />} />
    </Routes>
  );
};

export default UserRoute;
