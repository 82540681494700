import React from 'react';
import { Link } from 'react-router-dom';
import LeftArrowIcon from './icon/LeftArrowIcon';

const UserLayout = ({ title, children }) => {
  return (
    <div className="w-screen h-screen max-w-md flex flex-col bg-white px-6 pt-8 pb-[93px]">
      <header className="relative left-[-11px] h-[42px]">
        <Link to="/" aria-label="홈으로 가기">
          <LeftArrowIcon className="text-black" />
        </Link>
      </header>
      <div className="flex-1 flex justify-center items-center">
        <img
          src="/picture/finberry.png"
          alt="finberry"
          className="w-auto h-8"
        />
      </div>
      <main>{children}</main>
      <footer className="mt-6">
        {title === '로그인' ? (
          <div className="flex flex-col justify-center items-center text-sm text-grayDark2 gap-6">
            <Link to="/user/login/mobile">핸드폰 번호로 로그인하기</Link>
            <Link to="/user/signup">핸드폰 번호로 회원가입하기</Link>
          </div>
        ) : null}
      </footer>
    </div>
  );
};

export default UserLayout;
