import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CloseHeader from '../component/header/CloseHeader';
import PrimaryButton from '../component/button/PrimaryButton';
import HighlightIcon from '../component/icon/HighlightIcon';
import EmailIcon from '../component/icon/EmailIcon';

const options = [
  'AI 채팅의 응답 속도 및 이해도',
  '제공되는 도움말 및 설명의 명확성',
  '직관적인 디자인과 사용 흐름',
  '기능이나 메뉴의 접근성',
  '시뮬레이션의 이해도 및 사용 편의성'
];

const SurveyPopupPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const isUserAbandoned = query.get('isUserAbandoned') === 'true';

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const close = () => {
    setTimeout(() => window.close(), 100);
  };

  return (
    <div className="w-full mx-auto overflow-y-auto">
      <CloseHeader
        onClick={close}
        title="설문조사"
        dateInfo="2024년 09월 26일 목요일 오후 14:00"
      />
      <div className="border-t-2 border-orange bg-orangeLight4 p-4">
        <section className="mb-4">
          <div className="flex">
            <HighlightIcon />
            <p className="text-sm font-semibold">
              핀베리의 사용성을 향상 시키기 위한 설문조사
            </p>
          </div>
          <div className="pl-4">
            <p className="text-xs font-light mt-1">
              저희 핀베리는 미래 배우자와의 자산 흐름 시뮬레이션을 통해 스스로
              미래를 설계하고 재무적 자신감을 키워 현명한 재무 결정을 할 수
              있도록 돕습니다.
              <br />
              서비스 개선을 위해 간단한 설문에 참여해 주시면 서비스 무료 이용권
              쿠폰을 드립니다.
            </p>
          </div>
        </section>
        <section className="mb-4 bg-white rounded-lg p-4 px-6">
          <p className="text-sm font-semibold">
            서비스의 사용이 얼마나 쉬웠나요?
          </p>
          <div className="flex justify-between mt-2">
            {[1, 2, 3, 4, 5].map((rating) => (
              <button
                key={rating}
                onClick={() => handleOptionClick(rating)}
                className={`flex justify-center items-center w-10 h-10 rounded-[10px] bg-grayLight2 text-grayDark3 ${
                  selectedOption === rating
                    ? 'border-orange bg-orangeLight3'
                    : 'border-grayLight3 bg-grayLight2'
                }`}
              >
                <span
                  className={`w-7 h-7 rounded-full border-2 text-black text-sm flex justify-center items-center ${
                    selectedOption === rating
                      ? 'border-orange'
                      : 'border-grayDark3'
                  }`}
                >
                  {rating}
                </span>
              </button>
            ))}
          </div>
          <div className="flex justify-between text-xs text-grayDark3 text-center mt-2">
            <span>1 = 매우 어려움</span>
            <span>5 = 매우 쉬움</span>
          </div>
        </section>
        {isUserAbandoned && (
          <section className="mb-4 bg-white rounded-lg p-4 px-6">
            <p className="text-sm font-semibold">
              서비스 사용을 중단하거나 종료하게 된 이유는 무엇인가요?
            </p>
            <textarea
              rows={3}
              placeholder="답변을 입력해 주세요."
              className="w-full p-[10px] h-20 border rounded-[10px] mt-2 border-grayLight3 resize-none bg-grayLight2 text-grayDark3 text-[15px] leading-5"
            />
          </section>
        )}
        <section className="mb-4 bg-white rounded-lg p-4 px-6">
          <p className="text-sm font-semibold">
            서비스 사용 중 어떤 부분이 가장 편리하셨나요? 어려우셨다면, 어떤
            부분이 가장 어려우셨나요?
          </p>
          <div className="space-y-2">
            {options.map((option) => (
              <button
                key={option}
                onClick={() => handleOptionClick(option)}
                className={`flex items-center px-2 py-1.5 mt-3 w-full text-left text-sm border rounded-lg text-black ${
                  selectedOption === option
                    ? 'border-orange bg-orangeLight3'
                    : 'border-grayLight3 bg-grayLight2'
                }`}
              >
                {option}
              </button>
            ))}
            <label className="flex items-center px-2 py-1.5 mt-3 w-full text-left text-sm rounded-lg border-grayLight3 bg-grayLight2">
              <input
                type="text"
                name="feedback"
                placeholder="기타 의견"
                className="border-none outline-none bg-[initial]"
              />
            </label>
          </div>
        </section>
        <section className="mb-4 bg-white rounded-lg p-4 px-6">
          <p className="text-sm font-semibold">
            이 서비스가 앞으로 본인의 소비 결정이나 재정 관리를 더 현명하게 하는
            데 얼마나 도움이 될까요?
          </p>
          <div className="flex justify-between mt-2">
            {[1, 2, 3, 4, 5].map((rating) => (
              <button
                key={rating}
                onClick={() => handleOptionClick(rating)}
                className={`flex justify-center items-center w-10 h-10 rounded-[10px] bg-grayLight2 text-grayDark3 ${
                  selectedOption === rating
                    ? 'border-orange bg-orangeLight3'
                    : 'border-grayLight3 bg-grayLight2'
                }`}
              >
                <span
                  className={`w-7 h-7 rounded-full border-2 text-black text-sm flex justify-center items-center ${
                    selectedOption === rating
                      ? 'border-orange'
                      : 'border-grayDark3'
                  }`}
                >
                  {rating}
                </span>
              </button>
            ))}
          </div>
          <div className="flex justify-between text-xs text-grayDark3 text-center mt-2">
            <span>1 = 전혀 도움이 되지 않을 것 같음</span>
            <span>5 = 매우 큰 도움이 될 것 같음</span>
          </div>
        </section>
        <section className="mb-4 bg-white rounded-lg p-4 px-6">
          <p className="text-sm font-semibold">
            평소 재무나 재무설계에 대해 얼마나 이해하고 계시나요?
          </p>
          <div className="flex justify-between mt-2">
            {[1, 2, 3, 4, 5].map((rating) => (
              <button
                key={rating}
                onClick={() => handleOptionClick(rating)}
                className={`flex justify-center items-center w-10 h-10 rounded-[10px] bg-grayLight2 text-grayDark3 ${
                  selectedOption === rating
                    ? 'border-orange bg-orangeLight3'
                    : 'border-grayLight3 bg-grayLight2'
                }`}
              >
                <span
                  className={`w-7 h-7 rounded-full border-2 text-black text-sm flex justify-center items-center ${
                    selectedOption === rating
                      ? 'border-orange'
                      : 'border-grayDark3'
                  }`}
                >
                  {rating}
                </span>
              </button>
            ))}
          </div>
          <div className="flex justify-between text-xs text-grayDark3 text-center mt-2">
            <span>1 = 전혀 이해하지 못함</span>
            <span>5 = 매우 잘 이해함</span>
          </div>
        </section>
        <section className="mb-4 bg-white rounded-lg p-4 px-6">
          <p className="text-sm font-semibold">
            재무 관리에 더 도움이 되기 위해 어떤 기능이나 요소가 추가되면
            좋을까요?
          </p>
          <textarea
            rows={3}
            placeholder="답변을 입력해 주세요."
            className="w-full p-[10px] h-20 border rounded-[10px] mt-2 border-grayLight3 resize-none bg-grayLight2 text-grayDark3 text-[15px] leading-5"
          />
        </section>
        <section className="mb-4 bg-white rounded-lg p-4 px-6">
          <p className="text-sm font-semibold">
            향후 쿠폰을 받기 위해 이메일 주소를 적어주세요!
          </p>
          <p className="text-xs font-light mt-1">
            이메일은 경품 발송 외에는 사용되지 않으며, 안전하게 보호됩니다.
          </p>

          <div className="w-full p-2 mt-4 bg-black/5 rounded-[10px] border border-black/20 justify-start items-center gap-2.5 inline-flex">
            <div className="p-1 bg-black/20 rounded-md justify-center items-center gap-0.5 flex">
              <EmailIcon />
            </div>
            <input
              placeholder="name@example.com"
              className="text-black/60 text-[15px] font-medium leading-tight border-none outline-none bg-[initial]"
            />
          </div>
        </section>

        <div className="w-full py-2 mt-4">
          <PrimaryButton onClick={close}>답변 제출하기</PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default SurveyPopupPage;
