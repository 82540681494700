import { useRecoilValue } from 'recoil';
import { DataChart } from '../component/DataChart';
import { surveyState } from '../recoil/atoms';
const label1 = [
  { label: '비소비', index: 3 },
  { label: '소비', index: 10 }
];
const label2 = [
  { label: '비소비', index: 50 },
  { label: '소비', index: 20 }
];
const ChartPage = () => {
  const survey = useRecoilValue(surveyState);
  const { r_chartNoSpend, r_chartSpend } = survey;
  return (
    <DataChart
      data1={r_chartNoSpend}
      label1={label1}
      data2={r_chartSpend}
      label2={label2}
    />
  );
};

export default ChartPage;
