import { useRecoilValue } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
import { useAddMessage } from './useAddMessage';
import { useFetchCarList } from './useFetchCarList';
import { useMoveToTheQuestion } from './useMoveToTheQuestion';
import { useSetSurveyValue } from './useSetSurveyValue';

export const useSetNumberpadValue = () => {
  const setSurveyValue = useSetSurveyValue();
  const fetchCarList = useFetchCarList();
  const moveToTheQuestion = useMoveToTheQuestion();
  const survey = useRecoilValue(surveyState);
  const addMessage = useAddMessage();
  const setNumberpadValue = async (questionId, inputValue, setInputValue) => {
    console.log('setting numbers', questionId, survey.r_etc_goal_money);
    switch (questionId) {
      case config.AGE_IDX:
        setSurveyValue('r_age', inputValue);
        break;
      case config.SELF_MORTGAGE_IDX:
        setSurveyValue('r_selfMortgage', inputValue);
        break;
      case config.SPOUSE_MORTGAGE_IDX:
        setSurveyValue('r_spouseMortgage', inputValue);
        break;
      case config.MARRIAGE_AFTER_IDX:
        setSurveyValue('r_marriageYear', inputValue);
        break;
      case config.SELF_ETC_DEBT_IDX:
        setSurveyValue('r_selfEtcDebt', inputValue);
        break;
      case config.SPOUSE_ETC_DEBT_IDX:
        setSurveyValue('r_spouseEtcDebt', inputValue);
        break;
      case config.SELF_INCOME_IDX:
        setSurveyValue('r_selfIncome', inputValue);
        break;
      case config.SPOUSE_INCOME_IDX:
        console.log('SPOUSE_INCOME_IDX:', inputValue);
        setSurveyValue('r_spouseIncome', inputValue);
        break;
      case config.SELF_SAVINGS_IDX:
        console.log('SELF_SAVINGS_IDX:', inputValue);
        console.log('survey.r_selfIncome:', survey.r_selfIncome);
        if (parseInt(inputValue) > parseInt(survey.r_selfIncome)) {
          // alert('저축액이 너무 높습니다.');
          addMessage({
            questionText: '저축액이 너무 높습니다. 저축액을 다시 입력해주세요'
          });
          setInputValue('');
          return -1;
        }
        setSurveyValue('r_selfSaving', inputValue);
        break;
      case config.SPOUSE_SAVINGS_IDX:
        if (parseInt(inputValue) > parseInt(survey.r_spouseIncome)) {
          alert('저축액이 너무 높습니다.');
          setInputValue('');
          return -1;
        }
        setSurveyValue('r_spouseSaving', inputValue);
        break;
      case config.SELF_CASH_ASSET_IDX + 1:
        setSurveyValue('r_selfCashAsset', inputValue);
        break;
      case config.SPOUSE_CASH_ASSET_IDX + 1:
        setSurveyValue('r_spouseCashAsset', inputValue);
        break;
      case config.SELF_INVESTMENT_ASSET_IDX + 1:
        setSurveyValue('r_selfInvestmentAsset', inputValue);
        break;
      case config.SPOUSE_INVESTMENT_ASSET_IDX + 1:
        setSurveyValue('r_spouseInvestmentAsset', inputValue);
        break;
      case config.SELF_RETIREMENT_AGE_IDX:
        console.log('SELF_RETIREMENT_AGE_IDX:', inputValue);
        if (parseInt(inputValue) < parseInt(survey.r_age)) {
          // alert('퇴직 나이가 현재 나이보다 낮습니다.');
          addMessage({
            questionText:
              '퇴직 나이가 현재 나이보다 낮습니다. 나이를 다시 입력해주세요'
          });
          setInputValue('');
          return -1;
        }
        setSurveyValue('r_selfRetirementAge', inputValue);
        break;
      case config.SPOUSE_RETIREMENT_AGE_IDX:
        console.log('SPOUSE_RETIREMENT_AGE_IDX:', inputValue);
        if (parseInt(inputValue) < parseInt(survey.r_age)) {
          // alert('퇴직 나이가 현재 나이보다 낮습니다.');
          addMessage({
            questionText:
              '퇴직 나이가 현재 나이보다 낮습니다. 나이를 다시 입력해주세요'
          });
          setInputValue('');
          return -1;
        }
        setSurveyValue('r_spouseRetirementAge', inputValue);
        break;
      case config.CAR_PRICE_IDX:
        setSurveyValue('r_carPrice', inputValue);
        const result = await fetchCarList(inputValue);
        console.log('result:', result);
        if (result === -1) {
          // alert('해당 조건을 만족하는 차량이 없습니다.');
          addMessage({
            questionText:
              '해당 조건을 만족하는 차량이 없습니다. 다른 가격을 입력 해주세요.'
          });
          setInputValue('');
        }
        return result;
      case config.APARTMENT_PRICE_IDX:
        setSurveyValue('r_apartmentPrice', inputValue);
        break;
      case config.CHILDREN_AGE_IDX:
        setSurveyValue('r_children_age', inputValue);
        break;
      case config.EXTRA_GOAL_MONEY_IDX:
        console.log('EXTRA_GOAL_SURVEY_IDX:', inputValue);
        setSurveyValue('r_etc_goal_money', inputValue);
        break;
      case config.EXTRA_GOAL_YEAR_IDX:
        if (parseInt(inputValue) < parseInt(survey.r_age)) {
          // alert('목표 달성 나이가 현재 나이보다 낮습니다.');
          addMessage({
            questionText:
              '목표 달성 나이가 현재 나이보다 낮습니다. 나이를 다시 입력해주세요'
          });
          setInputValue('');
          return -1;
        }
        setSurveyValue('r_etc_goal_year', inputValue);
        break;
    }
    return 0;
  };

  return setNumberpadValue;
};
