import React, { useRef, useState } from 'react';
import { useSendConfirmCode } from '../../hook/useSendConfirmCode';
import { useVerifyConfirmCode } from '../../hook/useVerifyConfirmCode';
import BackHeader from '../../component/header/BackHeader';
import PrimaryButton from '../../component/button/PrimaryButton';
import ClockIcon from '../../component/icon/ClockIcon';

const MobilePhoneForm = ({ nextStep, prevStep, setUserData }) => {
  const sendConfirmCode = useSendConfirmCode();
  const verifyConfirmCode = useVerifyConfirmCode();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationMode, setIsVerificationMode] = useState(false);
  const [timer, setTimer] = useState(0);
  const intervalRef = useRef(null); // 타이머 ID 저장

  const maxPhoneLength = 11;
  const maxCodeLength = 4;

  const startTimer = () => {
    let timeLeft = 300; // 5분
    setTimer(timeLeft);

    // 기존 타이머 정리
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // 새 타이머 시작
    intervalRef.current = setInterval(() => {
      timeLeft -= 1;
      setTimer(timeLeft);
      if (timeLeft <= 0) {
        clearInterval(intervalRef.current);
        intervalRef.current = null; // 타이머 ID 초기화
      }
    }, 1000);
  };

  // 인증번호 전송/재전송 함수
  const handleSendCode = async () => {
    if (phoneNumber.length === maxPhoneLength) {
      try {
        const res = await sendConfirmCode(phoneNumber);
        console.log('res', res);
        if (res) {
          setIsVerificationMode(true);
          startTimer();
        } else {
          alert('인증번호 전송에 실패했습니다. 다시 시도해주세요.');
        }
      } catch (error) {
        console.error('Verification failed:', error);
        alert(
          error.response.data.error ||
            '인증번호 전송에 문제가 발생했습니다. 다시 시도해주세요.'
        );
      }
    } else {
      alert('유효한 전화번호를 입력해주세요.');
    }
  };

  const handleVerify = async () => {
    if (verificationCode.length === maxCodeLength) {
      try {
        const res = await verifyConfirmCode(phoneNumber, verificationCode);
        console.log('res', res);

        if (res) {
          alert('인증되었습니다.');
          // 초기화
          setPhoneNumber('');
          setVerificationCode('');
          setIsVerificationMode(false);
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
          setUserData((prev) => ({ ...prev, phoneNum: phoneNumber }));
          nextStep();
        } else {
          alert('인증에 실패했습니다. 다시 시도해주세요.');
        }
      } catch (error) {
        console.error('Verification failed:', error);
        alert('인증 중 문제가 발생했습니다. 다시 시도해주세요.');
      }
    } else {
      alert('인증번호를 올바르게 입력해주세요.');
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const onChangePhoneNumber = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setPhoneNumber(value);
  };

  return (
    <div className="flex flex-col h-screen px-4 py-8">
      <BackHeader goToPrev={prevStep} />
      <div className="flex-1">
        <div className="pt-6">
          <h1 className="text-2xl font-bold leading-normal tracking-[-0.48px]">
            휴대폰 번호를 알려주세요!
          </h1>
          <p className="mt-2 text-sm leading-[normal] text-grayDark2 whitespace-pre-line">
            {`회원가입, 로그인, 비밀번호 찾기에\n사용될 휴대폰 번호를 입력해주세요.`}
          </p>
        </div>
        {isVerificationMode === false ? (
          <div className="py-4 w-full">
            <div
              className={`flex border rounded-lg ${
                phoneNumber ? 'border-orange' : 'border-grayMid2'
              }`}
            >
              <input
                type="text"
                inputMode="tel"
                maxLength={maxPhoneLength}
                value={phoneNumber}
                onChange={(e) => onChangePhoneNumber(e)}
                placeholder="01012345678"
                className="outline-none rounded-lg p-3 text-[15px] font-semibold text-grayDark3 placeholder-grayMid w-full justify-start items-center gap-3 inline-flex bg-white"
              />
              <button
                onClick={handleSendCode}
                disabled={phoneNumber.length !== maxPhoneLength}
                className={`mx-3 w-28 text-sm font-semibold rounded-lg ${
                  phoneNumber.length === maxPhoneLength
                    ? 'text-orange'
                    : 'text-grayMid'
                }`}
              >
                인증번호 전송
              </button>
            </div>
          </div>
        ) : (
          <div className="py-4 w-full">
            <div
              className={`flex border rounded-lg border-grayMid2
              }`}
            >
              <input
                type="text"
                inputMode="tel"
                maxLength={maxPhoneLength}
                value={phoneNumber}
                onChange={(e) => onChangePhoneNumber(e)}
                placeholder="01012345678"
                className="outline-none rounded-lg p-3 text-[15px] font-semibold text-grayDark3 placeholder-grayMid w-full justify-start items-center gap-3 inline-flex bg-white"
              />
              <button
                onClick={handleSendCode}
                className={`mx-3 w-36 text-sm font-semibold rounded-lg text-orange ${
                  phoneNumber.length === maxPhoneLength ? '' : ''
                }`}
              >
                인증번호 재전송
              </button>
            </div>
            <div className="w-full mt-4">
              <label className="text-sm text-grayDark3">인증번호</label>
              <input
                type="text"
                inputMode="numeric"
                autoComplete="one-time-code"
                maxLength={maxCodeLength}
                value={verificationCode}
                onChange={(e) =>
                  setVerificationCode(e.target.value.replace(/[^0-9]/g, ''))
                }
                placeholder="4자리 숫자"
                className={`outline-none p-3 text-[15px] font-semibold text-grayDark3 placeholder-grayMid w-full rounded-lg border justify-start items-center gap-3 inline-flex ${
                  verificationCode ? 'border-orange' : 'border-grayMid2'
                }`}
              />
              <div className="text-grayDark3 text-xs flex items-center mt-2">
                <span className="ml-4">
                  <ClockIcon />
                </span>
                <span className="ml-2 font-semibold">
                  {timer > 0 ? formatTime(timer) : '시간 초과'}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="sticky w-full py-2">
        <PrimaryButton
          onClick={handleVerify}
          disabled={verificationCode.length !== maxCodeLength}
          className="rounded-full py-[8px] text-[15px] font-medium"
        >
          확인
        </PrimaryButton>
      </div>
    </div>
  );
};

export default MobilePhoneForm;
