import { useRecoilState } from 'recoil';
import ktorApi from '../api/ktorApi';
import { surveyState } from '../recoil/atoms';
import { useMoveToTheQuestion } from './useMoveToTheQuestion';
import { useSetSurveyValue } from './useSetSurveyValue';

export const useFetchApartmentList = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const { r_jeonseSale, r_apartmentPrice } = survey;
  const setSurveyValue = useSetSurveyValue();
  const moveToTheQuestion = useMoveToTheQuestion();

  // Modify this function to use async/await properly
  const fetchApartmentList = async (address1) => {
    try {
      let response;

      // Determine the correct URL based on the "jeonseSale" type
      if (r_jeonseSale === '전세') {
        response = await ktorApi.post('/apartments/jeonse', {
          price: r_apartmentPrice,
          address1: address1
        });
      } else {
        response = await ktorApi.post('/apartments/sale', {
          price: r_apartmentPrice,
          address1: address1
        });
      }

      console.log('Apartment List Received');
      setSurveyValue('r_apartmentList', response.data);

      // Return 0 to indicate success after getting the list
      return 0;
    } catch (error) {
      console.error('Error fetching apartment list:', error);
      // Return -1 on error
      return -1;
    }
  };

  return fetchApartmentList;
};
