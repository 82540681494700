import React from 'react';
import CloseIcon from '../../component/icon/CloseIcon';

const PolicyDetailModal = ({ close, fileContent }) => {
  return (
    <div className="fixed inset-0 left-1/2 transform -translate-x-1/2 w-full max-w-md bg-white z-50 overflow-y-auto px-7 py-8">
      <header className="sticky z-50 border-none text-black flex justify-between items-center">
        <div className="header-title">
          <h1 className="title">이용약관 상세</h1>
        </div>
        <button onClick={close} className="h-6 w-6">
          <CloseIcon className="text-black" />
        </button>
      </header>

      <div className="mt-4 whitespace-pre-line text-black text-[11px] font-normal leading-[24px]">
        {fileContent}
      </div>
    </div>
  );
};

export default PolicyDetailModal;
