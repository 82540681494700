import { useRecoilState } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';

export const useSelectedOptionSet = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);

  const selectedOptionSet = (option) => {
    console.log('option!!!!!!: ', option);
    switch (option.questionId) {
      case config.PURCHASE_IDX:
        setSurvey({ ...survey, r_purchase: [...survey.r_purchase, option] });
        break;
      case config.SELF_DEBT_IDX:
        setSurvey({ ...survey, r_self_debt: [...survey.r_self_debt, option] });
        break;
      case config.SPOUSE_DEBT_IDX:
        setSurvey({
          ...survey,
          r_spouse_debt: [...survey.r_spouse_debt, option]
        });
        break;
    }
  };
  return selectedOptionSet;
};
