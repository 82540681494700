import React from 'react';
import { Line } from 'rc-progress';

const ProgressBar = ({
  progress,
  color = '#FF6319',
  trailColor = '#FDEAE8',
  showTimerComment = false,
  showPercentage = true
}) => (
  <div className="w-full">
    {showTimerComment && (
      <p className="text-xs mb-2 text-center text-grayDark3">
        약 30초 정도 소요될 수 있어요
      </p>
    )}
    <Line
      percent={progress}
      strokeWidth="4"
      strokeColor={color}
      trailWidth="4"
      trailColor={trailColor}
    />
    {showPercentage && (
      <p className="text-sm mt-2 text-center">{Math.floor(progress)}%</p>
    )}
  </div>
);

export default ProgressBar;
