import { useRecoilState } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';

export const useHandleRemoveOption = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const { r_allQuestions, r_idx } = survey;
  const currentQuestion =
    r_allQuestions && r_allQuestions.length > 0 && r_allQuestions[r_idx];
  const handleRemoveOption = (option) => {
    switch (currentQuestion.questionId) {
      case config.PURCHASE_IDX:
        setSurvey((prevSurvey) => ({
          ...prevSurvey,
          r_selectedPurchase: prevSurvey.r_selectedPurchase.filter(
            (opt) => opt !== option
          )
        }));
        break;
      case config.SELF_DEBT_IDX:
        setSurvey((prevSurvey) => ({
          ...prevSurvey,
          r_selectedSelfDebt: prevSurvey.r_selectedSelfDebt.filter(
            (opt) => opt !== option
          )
        }));
        break;
      case config.SPOUSE_DEBT_IDX:
        setSurvey((prevSurvey) => ({
          ...prevSurvey,
          r_selectedSpouseDebt: prevSurvey.r_selectedSpouseDebt.filter(
            (opt) => opt !== option
          )
        }));
        break;
    }
  };
  return handleRemoveOption;
};
