export const formatKoreanCurrency = (value) => {
  if (value === '--') return value;

  const num = parseFloat(value);

  if (isNaN(num)) return '0';

  const isNegative = num < 0;

  const absNum = Math.abs(num);

  let result = '';

  if (absNum >= 100000000) {
    const eok = Math.floor(absNum / 100000000);
    const man = Math.floor((absNum % 100000000) / 10000);
    const rest = absNum % 10000;
    result =
      rest > 0
        ? `${eok.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}억 ${
            man > 0
              ? `${man.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}만 ${rest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              : `${rest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
          }`
        : man > 0
          ? `${eok.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}억 ${man.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}만`
          : `${eok.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}억`;
  } else if (absNum >= 10000) {
    const man = Math.floor(absNum / 10000);
    const rest = absNum % 10000;
    result =
      rest > 0
        ? `${man.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}만 ${rest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        : `${man.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}만`;
  } else {
    result = absNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return isNegative ? `-${result}` : result;
};
