import React, { Suspense, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import SimulationLoading from '../component/SimulationLoading';
import { useFetchAdjustedChartData } from '../hook/useFetchAdjustedChartData';
import { surveyState } from '../recoil/atoms';
const SimulationPage = () => {
  const SimulationContent = React.lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import('../component/SimulationContent')), 9900);
    });
  });

  const fetchReportData = useFetchAdjustedChartData();

  const [survey, setSurvey] = useRecoilState(surveyState);

  useEffect(() => {
    if (survey.r_chartNoSpend.ages) {
      fetchReportData(survey.r_chartNoSpend, 'r_chartNoSpendReport');
    }
  }, [survey.r_chartAltered]);

  useEffect(() => {
    if (survey.r_chartSpend.ages) {
      fetchReportData(survey.r_chartAdjusted, 'r_chartAdjustedReport');
    }
  }, [survey.r_chartAdjusted]);

  return (
    <Suspense fallback={<SimulationLoading />}>
      <SimulationContent />
    </Suspense>
  );
};

export default SimulationPage;
