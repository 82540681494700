import { useRecoilState } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
export const useAddMessage = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);

  const addMessage = async (newMessage) => {
    const lastMessage = survey.r_messages[survey.r_messages.length - 1];

    // console.log('last message:', lastMessage?.content);
    // console.log('new message:', newMessage?.questionText);
    // console.log(
    //   'same message?',
    //   lastMessage?.content?.questionText === newMessage?.questionText
    // );
    if (
      lastMessage?.content?.questionText === newMessage?.questionText &&
      lastMessage?.role === 'assistant'
    ) {
      return;
    }
    if (
      (!lastMessage || lastMessage.role === 'user') &&
      survey.r_idx !== config.SELF_CASH_ASSET_IDX &&
      survey.r_idx !== config.SPOUSE_CASH_ASSET_IDX &&
      survey.r_idx !== config.SELF_INVESTMENT_ASSET_IDX &&
      survey.r_idx !== config.SPOUSE_INVESTMENT_ASSET_IDX
    ) {
      await setSurvey((prevSurvey) => ({
        ...prevSurvey,
        r_messages: [
          ...prevSurvey.r_messages,
          { role: 'finberry', content: { questionText: '핀베리' } },
          { role: 'assistant', content: newMessage }
        ]
      }));
    } else {
      if (lastMessage !== newMessage?.questionText) {
        await setSurvey((prevSurvey) => ({
          ...prevSurvey,
          r_messages: [
            ...prevSurvey.r_messages,
            { role: 'assistant', content: newMessage }
          ]
        }));
      }
    }
  };
  return addMessage;
};
