import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../style/AdminPage.css';
import { addQuestion } from '../util/addQuestion';
import { addSurvey } from '../util/addSurvey';
import { getAllQuestion } from '../util/getAllQuestion';
import { getSurveys } from '../util/getSurvey';

const AdminPage = () => {
  const [surveys, setSurveys] = useState([]);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState('');

  useEffect(() => {
    getSurveys(setSurveys);
  }, []);

  useEffect(() => {
    getAllQuestion(selectedSurvey, setQuestionOptions);
  }, [selectedSurvey]);

  const handleSurveyChange = (event) => {
    const surveyId = event.target.value;
    setSelectedSurvey(surveyId);
    getAllQuestion(surveyId, setQuestionOptions);
  };

  const removeSurvey = (surveyId) => {
    // setSurveys(surveys.filter(survey => survey.id !== surveyId));
  };

  const removeOption = (optionId) => {
    setOptions(options.filter((option) => option.id !== optionId));
  };

  const handleAddSurvey = () => {
    addSurvey({ title: 'New Survey', surveyType: 'TEST' });
  };

  const handleAddQuestion = () => {
    addQuestion({
      questionText: 'New Question',
      surveyId: selectedSurvey,
      questionType: 'TEST',
      orderIndex: 0
    });
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post('http://localhost:8080/logout');
      sessionStorage.removeItem('authToken');
      window.location.href = '/';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="admin-page">
      <h1 className="text-2xl font-bold mb-4">Admin Page</h1>
      <button
        className="ml-2 p-2 bg-red text-white rounded"
        onClick={handleLogout}
      >
        LogOut
      </button>
      <div className="mb-4">
        <h2 className="text-xl font-semibold mb-2">Surveys</h2>
        <select
          className="survey-dropdown p-2 border rounded"
          value={selectedSurvey}
          onChange={handleSurveyChange}
        >
          <option value="">Select a survey</option>
          {surveys.map((survey) => (
            <option key={survey.id} value={survey.id}>
              {survey.title}
            </option>
          ))}
        </select>
        <button
          className="ml-2 p-2 bg-blue text-white rounded"
          onClick={handleAddSurvey}
        >
          Add Survey
        </button>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold mb-2">Questions</h2>
        {/* {questionOptions.map((question) => (
          <QuestionToggle
            key={question.id}
            question={question}
            options={question.options} // Filter options based on question
            onRemoveOption={removeOption}
          />
        ))} */}
        <button
          className="mt-2 p-2 bg-blue text-white rounded"
          onClick={handleAddQuestion}
        >
          Add Question
        </button>
      </div>
    </div>
  );
};

export default AdminPage;
