import { useRecoilState } from 'recoil';
import { surveyState } from '../recoil/atoms';

export const useMoveToTheQuestion = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const moveToTheQuestion = (moveTo) => {
    setTimeout(() => {
      setSurvey((prevSurvey) => ({
        ...prevSurvey,
        r_idx: moveTo - 1
      }));
    }, 800);
  };
  return moveToTheQuestion;
};
