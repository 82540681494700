import React from 'react';
import UserLayout from '../../component/UserLayout';
import UserForm from '../../component/UserForm';

const LoginForm = () => {
  return (
    <UserLayout title="로그인">
      <UserForm />
    </UserLayout>
  );
};

export default LoginForm;
