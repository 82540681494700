import React from 'react';
import CloseIcon from '../icon/CloseIcon';
import '../../style/Header.css';

const CloseHeader = ({ onClick = () => {}, title = '', dateInfo = '' }) => {
  return (
    <div className="close-header-container">
      <div className="header-title">
        <h1 className="title">{title}</h1>
        <p className="date-info">{dateInfo}</p>
      </div>

      <button onClick={onClick} className="close-button">
        <CloseIcon className="text-orange" />
      </button>
    </div>
  );
};

export default CloseHeader;
