import ktorApi from '../api/ktorApi';

export const login = async (userData, initializeUser) => {
  try {
    const response = await ktorApi.post('/login', userData);
    if (response.status === 200 || response.status === 201) {
      // 로그인 성공 후 유저 정보 초기화
      await initializeUser();
      return {
        success: true,
        data: response.data
      };
    } else {
      console.error('로그인 실패:', response.status);
      return {
        success: false,
        error: `Unexpected response status: ${response.status}`
      };
    }
  } catch (error) {
    console.error('로그인 중 오류 발생:', error);
    return {
      success: false,
      error: error.response?.data || '로그인 실패하였습니다. 다시 시도해주세요.'
    };
  }
};
