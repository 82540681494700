import { useRecoilState } from 'recoil';
import { surveyState } from '../recoil/atoms';
import { Numberpad } from './Numberpad';
import { TextInput } from './TextInput';

export const Footer = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const { r_allQuestions, r_idx } = survey;
  const currentQuestion =
    r_allQuestions && r_allQuestions.length > 0 && r_allQuestions[r_idx];

  const isNumberpad =
    currentQuestion?.questionType === 'AGE_INPUT' ||
    currentQuestion?.questionType === 'MONEY_INPUT' ||
    currentQuestion?.questionType === 'YEAR_AFTER' ||
    currentQuestion?.questionType === 'YEAR_INPUT';

  return (
    <div className="footer">
      {isNumberpad ? (
        <Numberpad />
      ) : survey.r_isCarListVisible ? (
        <></>
      ) : (
        <TextInput />
      )}
    </div>
  );
};
