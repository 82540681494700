import React from 'react';
import { useRecoilValue } from 'recoil';
import { config } from '../config';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import { surveyState } from '../recoil/atoms';
import '../style/ApartmentNamecard.css';
import { formatKoreanCurrency } from '../util/formatKoreanCurrency';
import PrimaryButton from './button/PrimaryButton';

export const ApartmentNamecard = () => {
  const survey = useRecoilValue(surveyState);
  const setSurveyValue = useSetSurveyValue();
  const isJeonse = survey.r_jeonseSale === '전세';
  return survey.r_apartmentList.length > 0 ? (
    <>
      <header className="flex justify-center py-2 font-bold">
        아파트 추천리스트
      </header>
      <div className="apartment-list">
        {survey.r_apartmentList.map((apartment, index) => {
          const {
            location,
            title,
            salePriceMin,
            salePriceMax,
            jeonsePriceMin,
            jeonsePriceMax
          } = apartment;
          const priceMin = isJeonse ? jeonsePriceMin : salePriceMin;
          const priceMax = isJeonse ? jeonsePriceMax : salePriceMax;

          return (
            index < 3 && (
              <div className="apartment-item" key={index}>
                <div
                  className={`apartment-item-number ${
                    index === 0
                      ? 'bg-orange text-white'
                      : 'bg-grayLight text-grayDark'
                  }`}
                >
                  <span>{(index + 1).toString().padStart(2, '0')}</span>
                </div>
                <div className="apartment-item-content">
                  <div className="apartment-details">
                    <p className="apartment-location">{location}</p>
                    <p className="apartment-name">{title}</p>
                    <p className="apartment-price">
                      {`${formatKoreanCurrency(priceMin)}~${formatKoreanCurrency(priceMax)}`}
                    </p>
                  </div>
                </div>
              </div>
            )
          );
        })}
        <PrimaryButton
          onClick={() => {
            if (survey.r_idx !== config.APARTMENT_NAMECARD_IDX) {
              return;
            }
            setSurveyValue(
              'r_isApartmentListVisible',
              !survey.r_isApartmentListVisible
            );
          }}
        >
          자세히 보기
        </PrimaryButton>
      </div>
    </>
  ) : (
    <>해당 조건에 맞는 아파트가 없습니다.</>
  );
};
