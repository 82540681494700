import api from '../api/api';

export const getAllQuestion = async (surveyId) => {
  try {
    const response = await api.get('/questions/surveyid=' + surveyId);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    // const jsonResponse = await response.json();
    return response;
  } catch (error) {
    console.error('Error fetching question:', error);
  }
};
