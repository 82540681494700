import React, { useState } from 'react';
import BackHeader from '../../component/header/BackHeader';
import PrimaryButton from '../../component/button/PrimaryButton';

const PasswordForm = ({ prevStep, setUserData, setIsReadyToSubmit }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState(false);

  const minLength = 8;
  const maxLength = 16;
  const hasMinLength = password.length >= minLength; // 최소 8자
  const hasUpperAndLower = /[A-Z]/.test(password) && /[a-z]/.test(password); // 영문 대소문자 혼합
  const hasNumber = /\d/.test(password); // 최소 한 개 숫자 포함
  const hasSpecialChar = /[@$!%*#?&]/.test(password); // 특수 문자 포함

  const regex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;

  // 비밀번호 유효성 검사
  const validatePassword = (password) => {
    if (!regex.test(password)) {
      if (!hasMinLength) {
        return '최소 8자 이상 입력해주세요.';
      }
      if (password.length > maxLength) {
        return '최대 16자까지 입력 가능합니다.';
      }
      return '영문, 숫자, 특수문자를 포함해야 합니다.';
    }
    return '';
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    const error = validatePassword(value);
    setIsValid(!error);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const isConfirmPasswordValid =
    !!confirmPassword && password === confirmPassword;

  const onClickConfirm = (e) => {
    e.preventDefault();
    setUserData((prev) => ({ ...prev, plainPassword: password }));
    setIsReadyToSubmit(true); // 상태 트리거
  };

  return (
    <div className="flex flex-col h-screen px-4 py-8">
      <BackHeader goToPrev={prevStep} />
      <div className="flex-1">
        <div className="pt-6">
          <h1 className="text-2xl font-bold leading-normal tracking-[-0.48px]">
            비밀번호를 적어주세요!
          </h1>
          <p className="mt-2 text-sm leading-[normal] text-grayDark2 whitespace-pre-line">
            {`핀베리에 로그인하기 위해 사용할\n비밀번호를 입력해주세요.`}
          </p>
        </div>
        <div className="flex flex-col w-full">
          <div className="w-full mt-4">
            <label className="text-sm text-grayDark3">비밀번호</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="영문·숫자·특수문자를 포함한 8~16자"
              className={`w-full border outline-none ${
                password ? 'border-orange' : 'border-grayMid2'
              } rounded-lg px-4 py-2 text-sm`}
            />
          </div>
          <div className="w-full mt-4">
            <label className="text-sm text-grayDark3">비밀번호 확인</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder="비밀번호를 다시 입력해주세요"
              className={`w-full border outline-none ${
                confirmPassword ? 'border-orange' : 'border-grayMid2'
              } rounded-lg px-4 py-2 text-sm`}
            />
            {confirmPassword && !isConfirmPasswordValid && (
              <span className="text-[#EA534B] text-xs mt-1">
                비밀번호 불일치
              </span>
            )}
          </div>
        </div>
        <div className="py-6 px-4 text-xs text-grayDark2">
          <p className={`${hasMinLength ? 'text-[#62BBB1]' : ''}`}>최소 8자</p>
          <p className={`${hasUpperAndLower ? 'text-[#62BBB1]' : ''}`}>
            영문 대소문자 혼합
          </p>
          <p className={`${hasNumber ? 'text-[#62BBB1]' : ''}`}>
            최소한 한 개 이상 숫자 포함
          </p>
          <p className={`${hasSpecialChar ? 'text-[#62BBB1]' : ''}`}>
            !, @, #, $, %, ^, &, * 등과 같은 특수 문자 포함
          </p>
        </div>
      </div>

      <div className="sticky w-full py-2">
        <PrimaryButton
          onClick={onClickConfirm}
          disabled={!isValid || !isConfirmPasswordValid}
          className="rounded-full py-[8px] text-[15px] font-medium"
        >
          확인
        </PrimaryButton>
      </div>
    </div>
  );
};

export default PasswordForm;
