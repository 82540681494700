import { useRecoilState } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
// import { saveAnswer } from '../util/saveAnswer';
import { useAddUserMessage } from './useAddUserMessage';
import { useSaveAnswer } from './useSaveAnswer';
export const useSelectedOptionSave = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const addUserMessage = useAddUserMessage();
  const { r_idx, r_allQuestions, r_sessionId, r_userId, r_surveyId } = survey;
  const currentQuestion =
    r_allQuestions && r_allQuestions.length > 0 && r_allQuestions[r_idx];
  const saveAnswer = useSaveAnswer();
  const selectedOptionSave = async () => {
    switch (currentQuestion.questionId) {
      case config.PURCHASE_IDX:
        let selectedPurchaseString = [];
        let selectedPurchaseId = [];
        survey.r_selectedPurchase.map((option) => {
          addUserMessage(option);
          selectedPurchaseString.push(option.optionText);
          selectedPurchaseId.push(option.id);
        });
        selectedPurchaseString = JSON.stringify(selectedPurchaseString);
        selectedPurchaseId = JSON.stringify(selectedPurchaseId);
        saveAnswer(
          currentQuestion.questionId,
          selectedPurchaseString,
          selectedPurchaseId
        );
        break;
      case config.SELF_DEBT_IDX:
        let selectedSelfDebtString = [];
        let selectedSelfDebtId = [];
        survey.r_selectedSelfDebt.map((option) => {
          selectedSelfDebtString.push(option.optionText);
          selectedSelfDebtId.push(option.id);
          addUserMessage(option);
        });
        selectedSelfDebtString = JSON.stringify(selectedSelfDebtString);
        selectedSelfDebtId = JSON.stringify(selectedSelfDebtId);
        saveAnswer(
          currentQuestion.questionId,
          selectedSelfDebtString,
          selectedSelfDebtId
        );
        break;
      case config.SPOUSE_DEBT_IDX:
        let selectedSpouseDebtString = [];
        let selectedSpouseDebtId = [];
        survey.r_selectedSpouseDebt.map((option) => {
          selectedSpouseDebtString.push(option.optionText);
          selectedSpouseDebtId.push(option.id);
          addUserMessage(option);
        });
        selectedSpouseDebtString = JSON.stringify(selectedSpouseDebtString);
        selectedSpouseDebtId = JSON.stringify(selectedSpouseDebtId);
        saveAnswer(
          currentQuestion.questionId,
          selectedSpouseDebtString,
          selectedSpouseDebtId
        );
        break;
    }
  };
  return selectedOptionSave;
};
