import { useRecoilState } from 'recoil';
import ktorApi from '../api/ktorApi';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import { surveyState } from '../recoil/atoms';

export const useFetchAllQuestions = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const setSurveyValue = useSetSurveyValue();
  const fetchAllQuestions = async (surveyId) => {
    await ktorApi.get('/questions/surveyid=' + surveyId).then((response) => {
      setSurveyValue('r_allQuestions', response.data);
    });
  };

  return fetchAllQuestions;
};
