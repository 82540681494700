import api from '../api/api';

export const getSurveys = async (setSurveys) => {
  try {
    const response = await api.get('/admin/surveys');
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    setSurveys(data);
  } catch (error) {
    console.error('Error fetching surveys:', error);
  }
};
