import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import ktorApi from '../api/ktorApi';
import { surveyState } from '../recoil/atoms';

export const useSavePhoneNum = () => {
  const survey = useRecoilValue(surveyState);
  const sessionId = survey.r_sessionId;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const savePhoneNum = async ({ phoneNum }) => {
    try {
      await ktorApi
        .post('/phone-nums', {
          phoneNum,
          ssid: sessionId
        })
        .then((response) => console.log(response));
      setIsModalVisible(true);
      return 0;
    } catch (error) {
      console.log('error: ', error);
      return -1;
    }
  };

  const closeModal = () => setIsModalVisible(false);

  return { savePhoneNum, isModalVisible, closeModal };
};
