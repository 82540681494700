const ResetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      className="text-black"
    >
      <path
        d="M15.0922 9.26676C14.6096 12.4743 11.842 14.9334 8.49998 14.9334C4.81808 14.9334 1.83331 11.9486 1.83331 8.26676C1.83331 4.58486 4.81808 1.6001 8.49998 1.6001C11.2337 1.6001 13.5832 3.24556 14.6119 5.6001"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8333 5.33333H14.7666C14.9876 5.33333 15.1666 5.15425 15.1666 4.93333V2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ResetIcon;
