import React from 'react';
import { useRecoilValue } from 'recoil';
import { config } from '../config';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import { surveyState } from '../recoil/atoms';
import '../style/CarNamecard.css';
import { formatKoreanCurrency } from '../util/formatKoreanCurrency';
import PrimaryButton from './button/PrimaryButton';

export const CarNamecard = () => {
  const survey = useRecoilValue(surveyState);
  const setSurveyValue = useSetSurveyValue();

  return survey.r_carList.length > 0 ? (
    <>
      <header className="flex justify-center py-2 font-bold">
        자동차 추천리스트
      </header>
      <div className="car-list">
        {survey.r_carList.map((car, index) => {
          return (
            index < 3 && (
              <div className="car-item" key={index}>
                <div
                  className={`car-item-number ${
                    index === 0
                      ? 'bg-orange text-white'
                      : 'bg-grayLight text-grayDark'
                  }`}
                >
                  <span>{`0${index + 1}`}</span>
                </div>
                <div className="car-item-content">
                  <img
                    src={`/picture/cars/${encodeURIComponent(car.picture_address)}`}
                    alt={`Car ${index + 1}`}
                    className="car-image"
                  />
                  <div className="car-details">
                    <p className="car-brand">{`${car.maker} - ${car.brand}`}</p>
                    <p className="car-name">{car.title}</p>
                    <p className="car-price">
                      {`${formatKoreanCurrency(car.price_min)} ~ ${formatKoreanCurrency(car.price_max)}만원`}
                    </p>
                  </div>
                </div>
              </div>
            )
          );
        })}
        <PrimaryButton
          onClick={() => {
            console.log('r_idx:', survey.r_idx);
            if (survey.r_idx !== config.CAR_NAMECARD_IDX) {
              return;
            }
            setSurveyValue('r_isCarListVisible', !survey.r_isCarListVisible);
          }}
        >
          자세히 보기
        </PrimaryButton>
      </div>
    </>
  ) : (
    <>조건에 만족하는 차량이 없습니다.</>
  );
};
