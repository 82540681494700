import api from '../api/api';

export const useVerifyConfirmCode = () => {
  const verifyConfirmCode = async (phoneNumber, code) => {
    const response = await api.post('/aligo/verify', {
      phoneNum: phoneNumber,
      code: code
    });
    return response.data;
  };
  return verifyConfirmCode;
};
