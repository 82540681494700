import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainPage from '../page/MainPage';

const HomeRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
    </Routes>
  );
};

export default HomeRoute;
