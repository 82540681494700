import axios from 'axios';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import RecoilizeDebugger from 'recoilize';
import './index.css';
import RoutesConfig from './route';

axios.defaults.withCredentials = true;

function App() {
  return (
    <RecoilRoot>
      <RecoilizeDebugger />
      <Router>
        <RoutesConfig />
      </Router>
    </RecoilRoot>
  );
}

export default App;
