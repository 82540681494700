import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { config } from '../config';
import { useAddUserMessage } from '../hook/useAddUserMessage';
import { useFetchCarList } from '../hook/useFetchCarList';
import { useMoveToNextQuestion } from '../hook/useMoveToNextQuestion';
import { useMoveToTheQuestion } from '../hook/useMoveToTheQuestion';
import { useSetNumberpadValue } from '../hook/useSetNumberpadValue';
import { useSetSurveyValue } from '../hook/useSetSurveyValue';
import { surveyState } from '../recoil/atoms';
import '../style/NumberPad.css';
import { formatKoreanCurrency } from '../util/formatKoreanCurrency';
// import { saveAnswer } from '../util/saveAnswer';
import { useSaveAnswer } from '../hook/useSaveAnswer';
import SendMessageIcon from './icon/SendMessageIcon';

export const Numberpad = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const { r_allQuestions, r_idx, r_sessionId, r_userId, r_surveyId } = survey;
  const currentQuestion =
    r_allQuestions && r_allQuestions.length > 0 && r_allQuestions[r_idx];
  const questionType = currentQuestion.questionType;
  const addUserMessage = useAddUserMessage();
  const moveToNextQuestion = useMoveToNextQuestion();
  const moveToTheQuestion = useMoveToTheQuestion();
  const setSurveyValue = useSetSurveyValue();
  const setNumberpadValue = useSetNumberpadValue();
  const fetchCarList = useFetchCarList();
  const saveAnswer = useSaveAnswer();
  const [inputValue, setInputValue] = useState('');
  const prefix =
    questionType === 'AGE_INPUT' || questionType === 'YEAR_INPUT'
      ? ' 만   '
      : questionType === 'YEAR_AFTER'
        ? ' 약   '
        : '';
  const suffix =
    questionType === 'MONEY_INPUT'
      ? '   원'
      : questionType === 'YEAR_AFTER'
        ? '   년 후 '
        : questionType === 'AGE_INPUT' || questionType === 'YEAR_INPUT'
          ? '   세'
          : '';

  const handleConfirm = async () => {
    if (
      (await setNumberpadValue(
        currentQuestion.questionId,
        inputValue,
        setInputValue
      )) === 0
    ) {
      console.log('saveAnswer', r_sessionId);
      saveAnswer(r_surveyId, currentQuestion.questionId, inputValue, null);
      const newMessage =
        questionType === 'MONEY_INPUT'
          ? formatKoreanCurrency(inputValue) + ' 원'
          : questionType === 'YEAR_AFTER'
            ? '약 ' +
              (parseInt(inputValue) > 100 ? '100' : inputValue) +
              '년 후'
            : questionType === 'AGE_INPUT'
              ? '만 ' + (parseInt(inputValue) > 100 ? '100' : inputValue) + '세'
              : inputValue;

      addUserMessage({ optionText: newMessage });

      if (currentQuestion.questionId === config.SELF_MORTGAGE_IDX) {
        setInputValue('');
        if (survey.r_selectedSelfDebt.length === 2) {
          moveToTheQuestion(config.SELF_ETC_DEBT_IDX);
          return;
        } else {
          moveToTheQuestion(config.SPOUSE_DEBT_IDX);
          return;
        }
      } else if (currentQuestion.questionId === config.SPOUSE_MORTGAGE_IDX) {
        setInputValue('');
        if (survey.r_selectedSpouseDebt.length === 2) {
          moveToTheQuestion(config.SPOUSE_ETC_DEBT_IDX);
          return;
        } else {
          moveToTheQuestion(config.SPOUSE_ETC_DEBT_IDX + 1);
        }
      } else if (currentQuestion.questionId === config.CHILDREN_AGE_IDX) {
        if (survey.r_selectedPurchase.some((item) => item.orderIndex === 4)) {
          setInputValue('');
          moveToTheQuestion(config.EXTRA_GOAL_SURVEY_IDX);
          return;
        } else {
          moveToTheQuestion(config.END_IDX);
        }
      }
      moveToNextQuestion();
      setInputValue('');
    }
  };
  return (
    <div className="number-pad-container px-[13px]">
      <div className="result-window">
        <span className="prefix">{prefix}</span>
        <span className="value">
          {inputValue !== '' ? formatKoreanCurrency(inputValue) : '      '}
        </span>
        <span className="suffix">{suffix}</span>
        <button
          type="submit"
          className="confirm-button"
          onClick={handleConfirm}
          disabled={inputValue === ''}
        >
          <SendMessageIcon
            className={`confirm-icon ${inputValue !== '' ? 'text-orange' : 'text-gray'}`}
          />
        </button>
      </div>
      <div className="number-pad">
        {[
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          questionType === 'MONEY_INPUT' ? '00' : '',
          '0'
        ].map((num) => (
          <button
            key={num}
            onClick={() => {
              if (
                questionType === 'YEAR_AFTER' ||
                questionType === 'AGE_INPUT'
              ) {
                if (parseInt(inputValue + num.toString(), 10) > 100) {
                  setInputValue('100');
                  return;
                }
              }
              setInputValue(
                parseInt(inputValue + num.toString(), 10).toString()
              );
            }}
            className="number-pad-button"
          >
            {num}
          </button>
        ))}
        <button
          className="number-pad-button"
          onClick={() => setInputValue(inputValue.slice(0, -1))}
        >
          <FontAwesomeIcon icon={faDeleteLeft} />
        </button>
      </div>
    </div>
  );
};
