import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CheckoutPage from '../page/payment';
import OrderPage from '../page/payment/OrderPage';
import SuccessPage from '../page/payment/SuccessPage';
import FailPage from '../page/payment/FailPage';

const CheckoutRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<CheckoutPage />} />
      <Route path="/order" element={<OrderPage />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/fail" element={<FailPage />} />
    </Routes>
  );
};

export default CheckoutRoute;
