import api from '../api/api';
import { useSetSurveyValue } from './useSetSurveyValue';

export const useFetchReportData = () => {
  const setSurveyValue = useSetSurveyValue();

  const fetchReportData = async (body, key) => {
    try {
      console.log('Fetching report data', body);
      const response = await api.post('/analyze/data', { plot_data: body });
      if (response.data) {
        console.log('response.data', response.data);
        setSurveyValue(key, response.data);
      } else {
        console.error('Empty response data');
      }
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
  };

  return fetchReportData;
};
