export const config = {
  START_IDX: 0,
  PURCHASE_IDX: 2,
  GENDER_IDX: 4,
  AGE_IDX: 5,
  SELF_INCOME_IDX: 6,
  SELF_SAVINGS_IDX: 7,
  SELF_RETIREMENT_AGE_IDX: 8,
  MARRIAGE_AFTER_IDX: 11,
  SPOUSE_INCOME_IDX: 12,
  SPOUSE_SAVINGS_IDX: 13,
  SPOUSE_RETIREMENT_AGE_IDX: 14,
  SELF_CASH_ASSET_IDX: 19,
  SPOUSE_CASH_ASSET_IDX: 21,
  SELF_INVESTMENT_ASSET_IDX: 23,
  SPOUSE_INVESTMENT_ASSET_IDX: 25,
  SELF_DEBT_IDX: 28,
  SELF_MORTGAGE_IDX: 29,
  SELF_ETC_DEBT_IDX: 30,
  SPOUSE_DEBT_IDX: 31,
  SPOUSE_MORTGAGE_IDX: 32,
  SPOUSE_ETC_DEBT_IDX: 33,
  APARTMENT_LIST_IDX: 34,
  SPOUSE_NAMECARD_IDX: 35,
  BASIC_SURVEY_END_IDX: 38,

  SIMULATION_START_IDX: 39,
  CAR_PRICE_IDX: 40,
  CAR_SURVEY_IDX: 42,
  CAR_LIST_IDX: 41,
  CAR_NAMECARD_IDX: 42,
  CAR_DETAIL_IDX: 43,
  CAR_CONFIRM_IDX: 44,

  APARTMENT_SURVEY_IDX: 45,
  APARTMENT_JEONSE_SALE_IDX: 46,
  APARTMENT_PRICE_IDX: 47,
  ADDRESS1_IDX: 48,
  ADDRESS2_IDX: 49,
  APARTMENT_NAMECARD_IDX: 51,
  APARTMENT_CONFIRM_IDX: 53,

  CHILDREN_SURVEY_IDX: 54,
  CHILDREN_AGE_IDX: 55,

  EXTRA_GOAL_SURVEY_IDX: 56,
  EXTRA_GOAL_MONEY_IDX: 57,
  EXTRA_GOAL_YEAR_IDX: 58,
  EXTRA_GOAL_CONFIRM_IDX: 59,

  END_IDX: 60,

  SPOUSE_RECOMMENDATION_QUESTION_ID: 100,

  CAPITAL_AREA: ['서울특별시', '인천광역시', '경기도'],
  KWANGWON_AREA: ['강원도'],
  CHOONGCHUNG_AREA: ['충청북도', '충청남도', '대전광역시', '세종특별자치시'],
  JEONRA_AREA: ['전라북도', '전라남도', '광주광역시'],
  KYUNGSANG_AREA: [
    '경상북도',
    '경상남도',
    '대구광역시',
    '부산광역시',
    '울산광역시'
  ],
  JEJU_AREA: ['제주시', '서귀포시'],

  FINBERRY_INFO: {
    title: '나만의 AI 재무 코치, 핀베리',
    description: '미래 배우자와의 자산 흐름은? 핀베리에서 시뮬레이션 해보세요!',
    price: 19800
  },

  // DELAY
  MESSAGE_BUBBLE_DELAY: 800,

  KAKAO_AUTH_URL: `https://ktor.finberry.store/kakao/auth`,
  KAKAO_LOGOUT_URL: `https://ktor.finberry.store/kakao/logout`,
  KAKAO_UNLINK_URL: `https://ktor.finberry.store/kakao/unlink`,

  NAVER_AUTH_URL: `https://ktor.finberry.store/naver/auth`,
  NAVER_UNLINK_URL: `https://ktor.finberry.store/naver/unlink`,

  GOOGLE_AUTH_URL: `https://ktor.finberry.store/google/auth`,
  GOOGLE_UNLINK_URL: `https://ktor.finberry.store/google/unlink`
};
