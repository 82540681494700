import { useRecoilState } from 'recoil';
import fastApiClient from '../api/fastApiClient';
import { surveyState } from '../recoil/atoms';

export const useFetchAdjustedChartData = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);

  const fetchAdjustedChartData = async (body, type) => {
    if (type === 'financial') {
      const resChartData = await fastApiClient.post('/simulate_full_3', body, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'uuid-header': survey.r_userId
        }
      });

      setSurvey((prev) => ({
        ...prev,
        r_chartAltered: resChartData.data.plot_data
      }));
    }

    if (type === 'expenditure') {
      const resChartData = await fastApiClient.post('/simulate_full_4', body, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'uuid-header': survey.r_userId
        }
      });

      setSurvey((prev) => ({
        ...prev,
        r_chartAdjusted: resChartData.data.plot_data
      }));
    }
  };

  return fetchAdjustedChartData;
};
