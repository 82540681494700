import React from 'react';
import { useRecoilValue } from 'recoil';
import { surveyState } from '../recoil/atoms';
import { formatKoreanCurrency } from '../util/formatKoreanCurrency';

export const SelectedApartmentNamecard = () => {
  const survey = useRecoilValue(surveyState);
  const { r_selectedApartment, r_jeonseSale } = survey;
  const price_max =
    r_jeonseSale === '전세'
      ? r_selectedApartment.jeonsePriceMax
      : r_selectedApartment.salePriceMax;
  const price_min =
    r_jeonseSale === '전세'
      ? r_selectedApartment.jeonsePriceMin
      : r_selectedApartment.salePriceMin;

  const detailsData = [
    {
      name: 'location',
      label: '위치',
      value: r_selectedApartment.location
    },
    {
      name: 'title',
      label: '이름',
      value: r_selectedApartment.title
    },
    {
      name: 'type',
      label: '형태',
      value: r_jeonseSale
    },
    {
      name: 'price',
      label: '가격',
      value: `${formatKoreanCurrency(price_min)}~${formatKoreanCurrency(price_max)}원`
    }
  ];

  return (
    <div className="flex flex-col justify-center items-center">
      <p className="font-semibold text-base pt-[1px] pb-[13px]">
        선택한 아파트
      </p>
      <div className="w-full p-5 rounded-[20px] bg-white">
        <div>
          {/* Details Section */}
          <div className="text-sm">
            {detailsData.map(({ name, label, value }) => (
              <div key={name} className="flex justify-between mb-1.5">
                <p className="w-2/5 text-sm text-left font-medium pl-2">
                  {label}
                </p>
                <p className="w-3/5 text-sm text-left font-normal pl-0">
                  {value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
