import { useRecoilState } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
// import { saveAnswer } from '../util/saveAnswer';
import { useAddUserMessage } from './useAddUserMessage';
import { useDeleteSelectedOptions } from './useDeleteSelectedOptions';
import { useMoveToTheQuestion } from './useMoveToTheQuestion';
import { useSaveAnswer } from './useSaveAnswer';
import { useSelectedOptionReset } from './useSelectedOptionReset';
import { useSelectedOptionSet } from './useSelectedOptionSet';
import { useSelectedOptionSort } from './useSelectedOptionSort';
import { useSkipValueSet } from './useSkipValueSet';
export const useHandleMultipleAnswerClick = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const deleteSelectedOptions = useDeleteSelectedOptions();
  const selectedOptionReset = useSelectedOptionReset();
  const selectedOptionSort = useSelectedOptionSort();
  const skipValueSet = useSkipValueSet();
  const addUserMessage = useAddUserMessage();
  const selectedOptionSet = useSelectedOptionSet();
  const moveToTheQuestion = useMoveToTheQuestion();
  const saveAnswer = useSaveAnswer();

  const handleMultipleAnswerClick = async (option) => {
    if (option.optionText === '넘어갈게요') {
      console.log('넘어갈게요');
      deleteSelectedOptions();
      // selectedOptionReset(option.questionId);
      addUserMessage({ optionText: '넘어갈게요' });
      skipValueSet(option.questionId);
      saveAnswer(option.questionId, '--', null);
      saveAnswer(option.questionId + 1, '--', null);
      moveToTheQuestion(option.nextQuestionId);
      return;
    } else if (option.optionText === '대출이 없어요') {
      if (
        option.questionId === config.SELF_DEBT_IDX ||
        option.questionId === config.SPOUSE_DEBT_IDX
      ) {
        deleteSelectedOptions();
        addUserMessage({ optionText: '대출이 없어요' });
        saveAnswer(option.questionId, 0, null);
        saveAnswer(option.questionId + 1, 0, null);
      } else if (option.optionText === '직접 입력할게요') {
        addUserMessage({ optionText: '직접 입력할게요' });
      }
      moveToTheQuestion(option.nextQuestionId);
      return;
    } else {
      selectedOptionSort(option);
      return;
    }
  };
  return handleMultipleAnswerClick;
};
