import React from 'react';

const SecondaryButton = ({ onClick = () => {}, children, className = '' }) => {
  return (
    <button
      onClick={onClick}
      className={`block w-full py-[14px] px-[10px] rounded-[5px] text-sm font-semibold border border-grayDark2 ${className}`}
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
