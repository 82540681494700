import { useRecoilValue } from 'recoil';
import ktorApi from '../api/ktorApi';
import { surveyState } from '../recoil/atoms';
export const useSaveAnswer = () => {
  const survey = useRecoilValue(surveyState);

  const saveAnswer = async (questionId, answerText, optionId) => {
    const response = await ktorApi.post('/answer', {
      sessionId: survey.r_sessionId,
      userId: Number(survey.r_userId),
      surveyId: survey.r_surveyId,
      questionId,
      answerText,
      optionId
    });
    return response.data;
  };

  return saveAnswer;
};
