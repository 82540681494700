import { useRecoilState } from 'recoil';
import { surveyState } from '../recoil/atoms';
import { formatKoreanCurrency } from '../util/formatKoreanCurrency';

export const SelectedCarNamecard = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);

  const detailsData = [
    {
      name: 'maker',
      label: '제조사',
      value: `${survey.r_selectedCar.maker}/${survey.r_selectedCar.brand}`
    },
    {
      name: 'carType',
      label: '차종',
      value: survey.r_selectedCar.car_type
    },
    {
      name: 'title',
      label: '차량 이름',
      value: survey.r_selectedCar.title
    },
    {
      name: 'fuelType',
      label: '연료 유형',
      value: survey.r_selectedCar.fuel_type
    },
    {
      name: 'price',
      label: '가격',
      value: `${formatKoreanCurrency(survey.r_selectedCar.price_min)}~${formatKoreanCurrency(survey.r_selectedCar.price_max)}만원`
    }
  ];

  return (
    <div className="flex flex-col justify-center items-center">
      <p className="font-semibold text-base pt-[1px] pb-[13px]">
        선택한 자동차
      </p>
      <div className="w-full p-5 rounded-[20px] bg-white">
        <div className="flex flex-col items-center p-[10px]">
          {/* Avatar Placeholder */}
          <div>
            <img
              src={`/picture/cars/${encodeURIComponent(survey.r_selectedCar.picture_address)}`}
              alt="Avatar"
              className="w-auto h-[87px]"
            />
          </div>
        </div>

        {/* Details Section */}
        <div className="pt-[4px] text-sm">
          {detailsData.map(({ name, label, value }) => (
            <div key={name} className="flex justify-between mb-1.5">
              <p className="w-2/5 text-sm text-left font-medium pl-3">
                {label}
              </p>
              <p className="w-3/5 text-sm text-left font-normal pl-3">
                {value}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
