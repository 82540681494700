import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
import '../style/SortUp.css';

export const SortUp = ({ sortIdx, setSortIdx, setIsSortUP }) => {
  const survey = useRecoilValue(surveyState);
  const { r_idx } = survey;

  const handleOptionChange = (e) => setSortIdx(Number(e.target.value));
  const sortRef = useRef();

  // Detect clicks outside the SortUp component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setIsSortUP(false); // Close the SortUp component
      }
    };

    // Add event listener to listen for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsSortUP]);

  const radioButtonList = [
    {
      value: 1,
      checked: sortIdx === 1,
      onChange: handleOptionChange,
      text: '가격 낮은 순'
    },
    {
      value: 2,
      checked: sortIdx === 2,
      onChange: handleOptionChange,
      text: '가격 높은 순'
    },
    {
      value: 3,
      checked: sortIdx === 3,
      onChange: handleOptionChange,
      text:
        r_idx === config.APARTMENT_NAMECARD_IDX
          ? '지역 이름 순'
          : '차량 이름 순'
    },
    r_idx === config.APARTMENT_NAMECARD_IDX && {
      value: 4,
      checked: sortIdx === 4,
      onChange: handleOptionChange,
      text: '아파트 이름 순'
    }
  ].filter(Boolean);

  return (
    <div className="sort-options-container" ref={sortRef}>
      <p className="pb-2 font-medium text-[15px] leading-6">정렬</p>
      <div className="radio-container">
        {radioButtonList.map((item, index) => (
          <label key={index} className="radio-button">
            <input
              type="radio"
              value={`${item.value}`}
              checked={item.checked}
              onChange={item.onChange}
              className="radio-button-input"
            />
            <span>{item.text}</span>
          </label>
        ))}
      </div>
    </div>
  );
};
