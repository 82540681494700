import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminPage from '../page/AdminPage';
import PrivateRoute from './PrivateRoute';
const AdminRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute element={AdminPage} />} />
    </Routes>
  );
};
export default AdminRoute;
