import React from 'react';
import { Link } from 'react-router-dom';
import BackIcon from '../component/icon/BackIcon';

const NotFoundPage = () => {
  return (
    <div className="max-w-md bg-white flex flex-grow flex-col justify-between items-center min-h-screen py-16 min-w-full">
      <div className="flex flex-col items-center">
        <h1 className="text-4xl font-bold text-gray-800">404</h1>
        <h2 className="text-base font-medium text-gray-600 mt-2">
          페이지를 찾을 수 없습니다.
        </h2>
      </div>

      <div className="flex flex-col items-center w-dvw max-w-md">
        <div className="text-center w-full">
          <div
            className="relative w-full aspect-[393/241] flex justify-center items-center bg-center bg-no-repeat bg-cover"
            style={{
              backgroundImage: 'url(/picture/404.png)'
            }}
          >
            <img
              src="/picture/ghost.png"
              alt="Ghost"
              className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-auto h-full p-1"
            />
          </div>
          <p className="text-base my-6">
            존재하지 않는 주소를 입력하셨거나,
            <br />
            요청하신 페이지의 주소가 변경 혹은
            <br />
            삭제되어 찾을 수 없습니다.
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <Link to="/">
          <button className="flex items-center justify-center w-52 h-14 border-[3px] rounded-[30px] text-black shadow-[inset_0px_0px_0px_7px_#FFF]">
            <BackIcon />
            <span className="font-semibold text-[15px] ml-2">
              홈으로 돌아가기
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
