import { useRecoilState } from 'recoil';
import { config } from '../config';
import { surveyState } from '../recoil/atoms';
import { useSetSurveyValue } from './useSetSurveyValue';

export const useSkipValueSet = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const setSurveyValue = useSetSurveyValue();
  const skipValueSet = (questionId) => {
    switch (questionId) {
      case config.SELF_DEBT_IDX:
        console.log('SELF_DEBT_IDX');
        setSurveyValue('r_selfEtcDebt', '--');
        setSurveyValue('r_selfMortgage', '--');
        break;
      case config.SPOUSE_DEBT_IDX:
        console.log('SPOUSE_DEBT_IDX');
        setSurveyValue('r_spouseEtcDebt', '--');
        setSurveyValue('r_spouseMortgage', '--');
        break;
      case config.SELF_CASH_ASSET_IDX:
        console.log('SELF_CASH_IDX');
        setSurveyValue('r_selfCashAsset', '--');
        break;
      case config.SPOUSE_CASH_ASSET_IDX:
        console.log('SPOUSE_CASH_IDX');
        setSurveyValue('r_spouseCashAsset', '--');
        break;
      case config.SELF_INVESTMENT_ASSET_IDX:
        console.log('SELF_INVESTMENT_IDX');
        setSurveyValue('r_selfInvestmentAsset', '--');
        break;
      case config.SPOUSE_INVESTMENT_ASSET_IDX:
        console.log('SPOUSE_INVESTMENT_IDX');
        setSurveyValue('r_spouseInvestmentAsset', '--');
        break;
      case config.SELF_MORTGAGE_IDX:
        console.log('SELF_MORTGAGE_IDX');
        setSurveyValue('r_selfMortgage', '--');
        break;
      case config.SPOUSE_MORTGAGE_IDX:
        console.log('SPOUSE_MORTGAGE_IDX');
        setSurveyValue('r_spouseMortgage', '--');
        break;
      case config.SELF_ETC_DEBT_IDX:
        console.log('SELF_ETC_DEBT_IDX');
        setSurveyValue('r_selfEtcDebt', '--');
        break;
      case config.SPOUSE_ETC_DEBT_IDX:
        console.log('SPOUSE_ETC_DEBT_IDX');
        setSurveyValue('r_spouseEtcDebt', '--');
        break;
    }
  };
  return skipValueSet;
};
