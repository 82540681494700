import { useRecoilState } from 'recoil';
import api from '../api/api';
import { surveyState } from '../recoil/atoms';
import { useSetSurveyValue } from './useSetSurveyValue';
export const useFetchOriginChartData = () => {
  const [survey, setSurvey] = useRecoilState(surveyState);
  const setSurveyValue = useSetSurveyValue();

  const fetchOriginChartData = async (body) => {
    // myAxios
    //   .post(process.env.REACT_APP_FETCH_CHARTDATA_URL, body, {
    //     withCredentials: true,
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   })
    //   .then((response) => {
    //     setSurveyValue('r_chartNoSpend', response.data.plot_data_1);
    //     setSurveyValue('r_chartSpend', response.data.plot_data_2);
    //     myAxios
    //       .post(process.env.REACT_APP_FETCH_REPORT_URL, response.data, {
    //         withCredentials: true
    //       })
    //       .then((response2) => {
    //         console.log('response2', response2.data);
    //         setSurveyValue(key + 'Report', response2.data.report);
    //       });
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching chart data:', error);
    //   });

    const resChartData = await api.post('/simulate_full_2', body, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'uuid-header': survey.r_userId
      }
    });

    setSurvey((prev) => ({
      ...prev,
      r_chartNoSpend: resChartData.data.plot_data_1,
      r_chartSpend: resChartData.data.plot_data_2
    }));
  };
  return fetchOriginChartData;
};
