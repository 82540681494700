import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { kakaoAccessTokenAtom } from '../recoil/kakaoAtoms';
const KakaoInfoPage = () => {
  const navigate = useNavigate();
  const accessToken = useRecoilValue(kakaoAccessTokenAtom);
  const [nickName, setNickName] = useState('');
  console.log('accessToken', accessToken);
  const getProfile = async () => {
    try {
      const response = await axios.get('https://kapi.kakao.com/v2/user/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Use accessToken instead of REST API key
          withCredentials: false
        }
      });
      console.log('User Info:', response.data);
      return response.data;
    } catch (error) {
      console.error(
        'Error fetching user info:',
        error.response?.data || error.message
      );
    }
  };
  const onClickBtn = () => {
    navigate('/');
  };
  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Fragment>
      <div>
        <h2>WELCOME! {nickName} 🙌</h2>
      </div>
      <button onClick={() => onClickBtn()}>Home으로 돌아가기</button>
    </Fragment>
  );
};

export default KakaoInfoPage;
