import React from 'react';
import PrimaryButton from './button/PrimaryButton';
import CloseIcon from './icon/CloseIcon';
import SecondaryButton from './button/SecondaryButton';

const AlertModal = ({
  onClick,
  onClose,
  close,
  message = '',
  buttonText = '확인',
  subButtonText = '',
  imgUrl = '/picture/icons/star-cloud.png'
}) => {
  return (
    <div
      style={{ pointerEvents: 'auto' }}
      className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-[316px] gap-[-24px] shadow-[0px_20px_25px_0px_rgba(28,28,34,0.15)] bg-white rounded-3xl"
    >
      <div
        className={`w-full pt-8 ${imgUrl ? 'pb-4' : ''} bg-white justify-center items-center inline-flex rounded-t-3xl`}
      >
        {close && (
          <button onClick={close} className="close-button top-3">
            <CloseIcon />
          </button>
        )}
        {imgUrl && (
          <div className="w-[156px] h-auto relative justify-center items-center flex">
            <img src={imgUrl} alt="" />
          </div>
        )}
      </div>
      <div className="rounded-3xl rounded-t-3xl bg-white">
        <div
          className={`flex flex-col justify-start items-start min-h-16 px-[30px] rounded-t-3xl ${imgUrl ? 'pt-6' : ''}`}
        >
          <div className="text-black text-[15px] leading-5 tracking-wide whitespace-pre-line">
            {message}
          </div>
        </div>
        <div className="w-full px-[30px] py-6">
          <div className="w-full rounded-full">
            <PrimaryButton
              onClick={onClick}
              className="rounded-[inherit] pt-[11px] pb-[11px]"
            >
              {buttonText}
            </PrimaryButton>
          </div>
          {onClose && (
            <div className="w-full rounded-full mt-2">
              <SecondaryButton
                onClick={onClose}
                className="rounded-[inherit] text-orange border-orange pt-[10px] pb-[10px]"
              >
                {subButtonText}
              </SecondaryButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
