import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isAuthenticatedState } from '../recoil/userAtom';
import AlertModal from '../component/AlertModal';
import PrimaryButton from '../component/button/PrimaryButton';
import { config } from '../config';
import { useSavePhoneNum } from '../hook/useSavePhoneNum';
import { routeConfig } from '../route/routeConfig';
import { formatPhoneNumber } from '../util/formatPhoneNumber';

const MainPage = () => {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const { savePhoneNum, isModalVisible, closeModal } = useSavePhoneNum();
  const [phoneNum, setPhoneNum] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const refElement = useRef(null);
  const navigate = useNavigate();

  const { price } = config.FINBERRY_INFO;
  const { LOGIN_ROUTE, TOSS_PAY_ROUTE } = routeConfig;
  const imgList = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  const handleAlert = () => {
    isAuthenticated ? navigate(TOSS_PAY_ROUTE) : toggleAlert();
  };

  const toggleAlert = () => {
    setIsAlertOpen((prev) => !prev);
  };

  const handleChange = (e) => {
    const formattedPhoneNum = formatPhoneNumber(e.target.value);
    setPhoneNum(formattedPhoneNum);
  };

  const handleSavePhoneNum = async () => {
    if (phoneNum) {
      try {
        const result = await savePhoneNum({ phoneNum });
        if (result === 0) {
          alert('Phone number saved successfully');
        } else {
          alert('이미 저장된 번호입니다.');
        }
      } catch (error) {
        console.error('Failed to save phone number:', error);
      }
    } else {
      alert('Please enter a phone number');
    }
    setPhoneNum('');
  };

  return (
    <div className="w-full max-w-md bg-purple">
      <div
        ref={refElement}
        className="relative w-full aspect-[100/200] pt-8 px-6"
      >
        {isAlertOpen && (
          <div className="absolute top-10 w-11/12">
            <AlertModal
              onClick={() => navigate(LOGIN_ROUTE)}
              close={toggleAlert}
              message={
                <>
                  <strong>로그인이 필요한 서비스입니다!</strong>
                  <p>
                    핀베리는 로그인이 필요한 서비스입니다. 로그인 먼저 하신 후
                    이용해주세요!
                  </p>
                </>
              }
              imgUrl=""
            />
          </div>
        )}
        <header className="flex justify-between items-center h-[30px]">
          <img
            src="/picture/finberry-white.png"
            alt="logo"
            className="w-auto h-full"
          />
          {isAuthenticated ? (
            <img
              src="/picture/icons/logo-profile.png"
              alt="profile"
              className="w-auto h-full"
            />
          ) : (
            <Link to={LOGIN_ROUTE} className="flex">
              <span className="text-white text-[14px] font-medium tracking-[-0.28px] mr-1">
                Log in / Join
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M8 6L12 10L8 14"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          )}
        </header>
        <div className="mt-[26px] pt-[18px] rounded-2xl text-white bg-gradient-to-br from-[#ec5b16] to-[#ffad45]">
          <div className="flex justify-end mb-[18px]">
            <span className="w-[84px] text-center py-1 mr-[18px] bg-black rounded-full text-sm font-semibold leading-normal tracking-[-0.28px]">
              ₩ {price.toLocaleString()}
            </span>
          </div>
          <div>
            <p className="text-white text-center text-[32px] font-bold leading-[120%] tracking-[-0.64px]">
              결혼부터 은퇴까지
            </p>
            <p className="text-white text-center text-[32px] font-bold leading-[120%] tracking-[-0.64px]">
              AI를 활용한
            </p>
            <p className="text-white text-center text-[32px] font-bold leading-[120%] tracking-[-0.64px]">
              프리미엄 재무 설계
            </p>
          </div>
          <div className="my-5 text-center text-white text-[16px] leading-[150%] tracking-[-0.32px]">
            <p className="font-light">
              <span className="font-semibold">AI 재무 코칭 챗봇 ‘핀베리’</span>
              로
            </p>
            <p className="font-light">미래 자산의 변화를 예측해보세요!</p>
          </div>
          <div className="flex justify-center">
            <button
              onClick={handleAlert}
              className="w-[222px] rounded-full py-3 text-lg font-bold tracking-[-0.36px] bg-white text-orange leading-[150%]"
            >
              {price.toLocaleString()}원 구매하기
            </button>
          </div>

          <div className="w-full">
            <img
              src="/picture/main/simulation-sample.png"
              alt="simulation sample"
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>

      {imgList.map((img) => (
        <div key={img}>
          <img
            src={`/picture/main/${img}.png`}
            alt={`${img}`}
            className="w-full h-auto"
          />
        </div>
      ))}

      <div className="pt-8 pb-24 bg-white">
        <div className="pb-10">
          <div className="flex flex-col justify-center items-center text-[26px] font-bold leading-[130%] tracking-[-0.52px]">
            <p>출시 알림 신청하고</p>
            <p>핀베리를 가장 먼저 만나보세요</p>
          </div>
          <div className="mt-4 flex flex-col justify-center items-center text-[rgba(43,22,12,0.70)] text-center text-[14px] font-normal leading-[160%] tracking-[-0.28px]">
            <p>정식 버전이 출시되면 다양한 이벤트 정보와 함께</p>
            <p>문자로 안내해드릴게요</p>
          </div>
          <div className="mt-10 flex flex-col items-center justify-center">
            <div className="w-[257px]">
              <input
                type="tel"
                value={phoneNum}
                onChange={handleChange}
                placeholder="휴대폰 번호를 입력하세요"
                className="block w-full py-[14px] px-[10px] rounded-[5px] text-sm text-center text-[14px] border border-[rgba(43,22,12,0.20)] placeholder-[rgba(43,22,12,0.30)] focus:outline-none"
              />
            </div>
            <div className="w-[257px] mt-4">
              <PrimaryButton onClick={handleSavePhoneNum}>
                출시 알림 받기
              </PrimaryButton>
            </div>
          </div>
        </div>

        <footer>
          <div className="flex justify-center pt-10 pb-8">
            <img
              src="/picture/finberry.png"
              alt="logo"
              className="h-[34px] w-auto"
            />
          </div>
          <div className="text-[10px] text-center leading-[160%] tracking-[-0.2px] text-[rgba(43,22,12,0.70)]">
            <p>주식회사 비해피 Company Reg. 224-88-02373</p>
            <p>CEO 이도경 Network Reg. 제 2024-광주서구-0118호</p>
            <p>(본점)</p>
            <p>광주 서구 시청로 67 프리미엄아울렛 4층 17호</p>
            <p>(기업부설연구소)</p>
            <p>
              서울특별시 영등포구 의사당대로83, 오투타워 5층 115호 서울핀테크랩
            </p>
            <p>E-mail setmefree15@naver.com</p>
            <p>Tel 070-4578-4998</p>
          </div>
        </footer>
      </div>

      {/* 성공 시 모달 표시 */}
      {isModalVisible && (
        <AlertModal
          onClick={closeModal}
          message={`남겨주신 연락처로 추후 업데이트 소식을\n빠르게 알려드리겠습니다. 감사합니다!`}
        />
      )}
    </div>
  );
};

export default MainPage;
