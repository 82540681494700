const HighlightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      className="text-orangeLight2"
    >
      <rect
        x="12"
        width="20"
        height="8"
        rx="4"
        transform="rotate(90 12 0)"
        fill="currentColor"
      />
    </svg>
  );
};

export default HighlightIcon;
