import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../util/login';
import { useFetchUser } from '../../hook/useFetchUser';
import PrimaryButton from '../../component/button/PrimaryButton';
import BackHeader from '../../component/header/BackHeader';

const MobileLoginPage = () => {
  const { initializeUser } = useFetchUser();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const maxPhoneLength = 11;

  const onChangePhoneNumber = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setPhoneNumber(value);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      phoneNum: phoneNumber,
      plainPassword: password
    };

    const result = await login(userData, initializeUser);
    if (result.success) {
      alert('로그인이 성공적으로 완료되었습니다.');
      navigate('/');
    } else {
      alert(`${result.error}`);
    }
  };

  return (
    <div className="h-screen w-full max-w-md">
      <div className="flex flex-col h-screen px-4 py-8">
        <BackHeader />
        <div className="flex-1">
          <div className="pt-6">
            <h1 className="text-2xl font-bold leading-normal tracking-[-0.48px]">
              휴대폰 번호로 로그인하기
            </h1>
            <p className="mt-2 text-sm leading-[normal] text-grayDark2 whitespace-pre-line">
              회원가입시 등록한 휴대폰 번호를 입력해주세요.
            </p>
          </div>
          {/* 휴대폰 번호 */}
          <div className="w-full mt-4">
            <label className="text-sm text-grayDark3">휴대폰번호</label>
            <div
              className={`flex border rounded-lg ${
                phoneNumber ? 'border-orange' : 'border-grayMid2'
              }`}
            >
              <input
                type="text"
                inputMode="tel"
                maxLength={maxPhoneLength}
                value={phoneNumber}
                onChange={(e) => onChangePhoneNumber(e)}
                placeholder="01012345678"
                className="outline-none rounded-lg p-2 text-[15px] font-semibold text-grayDark3 placeholder-grayMid w-full justify-start items-center gap-3 inline-flex bg-white"
              />
            </div>
          </div>
          {/* 비밀번호 */}
          <div className="w-full">
            <div className="w-full mt-4">
              <label className="text-sm text-grayDark3">비밀번호</label>
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="영문·숫자·특수문자를 포함한 8~16자"
                className={`w-full border outline-none rounded-lg p-2 text-[15px] font-semibold text-grayDark3 placeholder-grayMid ${
                  password ? 'border-orange' : 'border-grayMid2'
                }`}
              />
            </div>
          </div>
        </div>

        <div className="sticky w-full py-2">
          <PrimaryButton
            onClick={handleSubmit}
            disabled={!phoneNumber || !password}
            className="outline-none rounded-full py-[8px] text-[15px] font-medium"
          >
            확인
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default MobileLoginPage;
