export const formatChartLabel = (number) => {
  if (typeof number !== 'number') {
    return 'Invalid input';
  }

  const isNegative = number < 0; // Check if the number is negative
  const absNumber = Math.abs(number); // Use the absolute value for formatting

  let formattedValue;
  if (absNumber >= 100000000) {
    formattedValue = (absNumber / 100000000).toFixed(1) + '억'; // Billion unit
  } else if (absNumber >= 10000) {
    formattedValue = (absNumber / 10000).toFixed(1) + '만'; // Ten-thousand unit
  } else {
    formattedValue = absNumber.toString(); // Just return the number itself if it's smaller
  }

  return isNegative ? `-${formattedValue}` : formattedValue; // Add negative sign if needed
};
